import { useStaticQuery, graphql } from "gatsby"

const useSocialMediaMenuData = () => {
  const data = useStaticQuery(graphql`
    query {
      menuYaml(title: { eq: "socialMenu" }) {
        id
        title
        items {
          color
          component
          description
          id
          label
          publish
          url
          variant
        }
      }
    }
  `)
  const items = data.menuYaml.items.filter((item) => item.publish === true)
  return items
}

export default useSocialMediaMenuData
