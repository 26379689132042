/** @jsx jsx */
import { jsx, Badge } from "theme-ui";
import { darken } from "@theme-ui/color";
import { Link } from "@smerth/gatsby-theme-theme-ui";

function Tag({ className, item, noCount, variant, inline }) {
  return (
    <Link
      className={className}
      key={item.id}
      href={item.url}
      variant={variant}
      sx={{
        px: 1,
        borderRadius: "md",
        lineHeight: "normal",
        bg: variant !== "ui" ? variant : "accent",
        display: inline ? "inline-block" : "flex",
        alignItems: "center",
        gap: 1,
        textDecoration: "none",
        "&:hover": {
          bg:
            variant !== "ui"
              ? (t) => `${darken(`${variant}`, 0.03)(t)}`
              : (t) => `${darken("accent", 0.02)(t)}`,
        },
      }}
    >
      {item.label}

      {!noCount && item.count !== 1 ? (
        <Badge
          as="div"
          variant={variant}
          sx={{ fontSize: "70%", fontWeight: "normal" }}
        >
          {item.count}
        </Badge>
      ) : (
        ""
      )}
    </Link>
  );
}

export default Tag;
