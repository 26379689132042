// TODO: implement a fix for line-height / leading

// Scales and CSS from: https://type-scale.com/
const headingBaseStyles = {
  fontFamily: "sans",
  fontWeight: "normal",
  lineHeight: "normal",
  letterSpacing: "heading",
  color: "inherit",
  a: {
    color: "inherit",
    textDecoration: "none",
    svg: {
      mr: [2, 3, 4],
    },
  },
};

const uiTextBaseStyles = {
  fontFamily: "sans",
  fontWeight: "light",
  lineHeight: "normal",
  letterSpacing: "ui",
  m: "0",
  a: {
    textDecoration: "none",
    svg: {},
  },
};

const displayTextBaseStyles = {
  color: "inherit",
  fontFamily: "sans",
  fontWeight: "display",
  m: "0",
  lineHeight: 3,
  letterSpacing: "display",
  a: {
    color: "inherit",
    textDecoration: "none",
    svg: {
      mr: [2, 3, 4],
    },
  },
};

const headingStyles = {
  pageTitle: {
    color: "primary",
    fontFamily: "sans",
    fontWeight: "display",
    letterSpacing: "display",
    margin: "0rem 0rem 1.38rem",
    fontSize: [9, 12, 11, 14, 15],
    a: {
      color: "inherit",
      textDecoration: "none",
      svg: {
        mr: [2, 3, 4],
      },
    },
  },
  h1: {
    color: "inherit",
    fontFamily: "sans",
    fontWeight: "display",
    letterSpacing: "display",
    margin: "0rem 0rem 1.38rem",
    fontSize: [12, 13, 13, 14, 15],
    a: {
      color: "secondary",
      textDecoration: "none",
      svg: {
        mr: [2, 3, 4],
      },
    },
  },
  h2: {
    ...headingBaseStyles,
    margin: "3rem 0 1.38rem 0",
    fontSize: [10, 11, 12, 13, 14],
  },
  h3: {
    ...headingBaseStyles,
    margin: "3rem 0 1.38rem",
    fontSize: [9, 10, 11, 12, 13],
  },
  h4: {
    ...headingBaseStyles,
    margin: "3rem 0 1.38rem",
    fontSize: [8, 9, 10, 11, 12],
  },
  h5: {
    ...headingBaseStyles,
    margin: "3rem 0 1.38rem",
    fontSize: [7, 8, 9, 10, 11],
  },
  h6: {
    ...headingBaseStyles,
    margin: "3rem 0 1.38rem",
    fontSize: [6, 7, 8, 9, 10],
  },
};

const siteNameBaseStyles = {
  fontFamily: "sans",
  fontWeight: "bold",
  lineHeight: ".9",
  letterSpacing: "heading",
  color: "inherit",
  a: {
    color: "inherit",
    textDecoration: "none",
    svg: {
      mr: [2, 3, 4],
    },
  },
};

const siteSloganBaseStyles = {
  fontFamily: "condensed",
  fontWeight: "light",
  lineHeight: ".95",
  letterSpacing: "ui",
  m: "0",
};

const typographyBaseStyles = {
  headingBaseStyles,
  headingStyles,
  uiTextBaseStyles,
  displayTextBaseStyles,
  siteNameBaseStyles,
  siteSloganBaseStyles,
};

export default typographyBaseStyles;
