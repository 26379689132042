// colorModes
import colors from "./colors/colors";
import scales from "./scales";
import styles from "./styles/index";
import variants from "./variants/";

// TODO: move settings to their own folder
export default {
  // Helps mitigate a flash of unstyled content during rehydration
  useCustomProperties: true,

  // Adds theme.styles.root to the <body> element along with color and background-color
  useBodyStyles: true,

  // Sets a name for the initial color mode
  // initialColorModeName: "light",

  // Initializes the color mode based on the prefers-color-scheme media query
  useColorSchemeMediaQuery: true,

  // Persist color mode in local storage
  useLocalStorage: true,

  styles,
  colors,
  ...scales,
  ...variants,
};
