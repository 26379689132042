/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui";
// import ScreenReaderText from "./../ScreenReaderText";
import { FiSunrise, FiSunset } from "react-icons/fi";
import { Button } from "@smerth/gatsby-theme-theme-ui";
import { useEffect } from "react";

function ColorModeToggle({ className, variant, ...rest }) {
  // const [mode, setMode] = useColorMode("light");
  const [colorMode, setColorMode] = useColorMode();

  // function onClick(e) {
  //   setColorMode(colorMode === "default" ? "dark" : "light");
  //   // mode === "dark" ? setMode("light") : setMode("dark");
  // }

  let text = (colorMode) => {
    return (text =
      colorMode === "dark" ? "Switch to light theme" : "Switch to dark theme");
  };

  // useEffect(() => {
  //   text(colorMode);
  // }, [colorMode]);

  const renderIconComponent = () => {
    if (colorMode === "dark") {
      return <FiSunrise />;
    } else {
      return <FiSunset />;
    }
  };

  return (
    <Button
      className={className}
      variant={variant}
      sx={{
        color: "inherit",
        bg: "inherit",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={(e) => {
        setColorMode(colorMode === "light" ? "dark" : "light");
      }}
      title={
        colorMode === "dark" ? "Switch to light theme" : "Switch to dark theme"
      }
      {...rest}
    >
      {renderIconComponent()}
    </Button>
  );
}

export default ColorModeToggle;
