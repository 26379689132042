const cards = {
  default: {
    borderRadius: "sm",
    overflow: "hidden",
    "&:hover, &:focus": {
      boxShadow: 1,
    },
    "> a": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  base: {
    color: "text",
    display: "grid",
    borderRadius: "none",
    boxShadow: 1,
    img: {
      justifySelf: "center",
    },
  },
  light: {
    variant: "cards.default",
    color: "text",
    bg: "background",
  },

  primary: {
    variant: "cards.default",
    color: "white",
    bg: "primary",
  },
  secondary: {
    variant: "cards.base",
    color: "white",
    bg: "secondary",
  },
  muted: {
    variant: "cards.base",
    color: "text",
    bg: "muted",
  },
  accent: {
    variant: "cards.base",
    color: "text",
    bg: "accent",
  },
  white: {
    variant: "cards.base",
    color: "black",
    bg: "white",
  },
  black: {
    variant: "cards.base",
    color: "white",
    bg: "black",
  },
  documentation: {
    displayCode: {
      color: "text",
      borderWidth: (theme) => `${theme.borderWidths.thin}`,
      borderColor: (theme) => `${theme.colors.accent}`,
      borderStyle: (theme) => `${theme.borderStyles.solid}`,
      "> h1": {
        display: "flex",
        justifyContent: "space-between",
        p: [2, 3],
        bg: "accent",
        /* width: "100%", */
      },
    },
    matrix: {
      color: "text",
      borderWidth: (theme) => `${theme.borderWidths.thin}`,
      borderColor: (theme) => `${theme.colors.muted}`,
      borderStyle: (theme) => `${theme.borderStyles.solid}`,
      "> h1": {
        p: [2, 3],
        fontWeight: "bold",
        display: "flex",
        justifyContent: "space-between",
        color: "white",
        bg: "secondary",
        width: "100%",
      },
    },
    componentArray: {
      display: "flex",
      flexDirection: "column",
      color: "text",
      borderWidth: (theme) => `${theme.borderWidths.thin}`,
      borderColor: (theme) => `${theme.colors.muted}`,
      borderStyle: (theme) => `${theme.borderStyles.solid}`,
      "> h1": {
        p: [2, 3],
        bg: "muted",
        width: "100%",
      },
    },
    liveCodeBlock: {
      color: "text",
      borderWidth: (theme) => `${theme.borderWidths.thin}`,
      borderColor: (theme) => `${theme.colors.primary}`,
      borderStyle: (theme) => `${theme.borderStyles.solid}`,
      "> h1": {
        display: "flex",
        justifyContent: "space-between",
        fontSize: 4,
        p: [2, 3],
        color: "white",
        bg: "primary",
        width: "100%",
      },
    },
  },
};

export default cards;
