import { Box, Text } from "theme-ui";
import { BsApple } from "react-icons/bs";
import { Alert, DisplayComponentVariants, FlexMenuLink, MenuVertical } from "@smerth/gatsby-theme-theme-ui";
import menu from "../../../../documentation/data/menu.yaml";
import * as React from 'react';
export default {
  Box,
  Text,
  BsApple,
  Alert,
  DisplayComponentVariants,
  FlexMenuLink,
  MenuVertical,
  menu,
  React
};