/** @jsx jsx */
import { jsx } from "theme-ui";

// This comes from flex course on Lynda (can't remember the point...)
function ScreenReaderText(props) {
  return (
    <span
      {...props}
      sx={{
        clip: "rect(1px, 1px, 1px, 1px)",
        position: "absolute !important",
        height: "1px",
        width: "1px",
        overflow: "hidden",
        "&:hover, &:active, &:focus": {
          backgroundColor: "#f1f1f1",
          borderRadius: 0,
          boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.6)",
          clip: "auto !important",
          color: "#21759b",
          display: "block",
          fontSize: "14px",
          fontWeight: "bold",
          height: "auto",
          left: "5px",
          lineHeight: "normal",
          textDecoration: "none",
          top: "5px",
          width: "auto",
          zIndex: "100000",
        },
      }}
    />
  );
}

export default ScreenReaderText;
