/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import {
  ColorModeToggle,
  FlexMenu,
  SiteLogo,
  SiteBranding,
  Link,
} from "@smerth/gatsby-theme-theme-ui"

import useDesktopMenuData from "../../../hooks/graphql/use-desktop-menu-data"
import useSiteMetadata from "../../../hooks/graphql/use-site-metadata"
import { GrGithub } from "react-icons/gr"

function OverlayHeaderDesktop(props) {
  const { className } = props
  const siteMetadata = useSiteMetadata()
  const data = useDesktopMenuData()

  const menuData = data.filter((item) => item.label !== "Github")

  const componentMap = {
    SiteName: (props) => {
      return (
        <SiteBranding
          siteName={siteMetadata.title}
          siteSlogan={siteMetadata.slogan}
          size="sm"
          // hideSlogan
          {...props}
        >
          <SiteLogo size="48px" />
        </SiteBranding>
      )
    },
    // GithubLink: GithubLink,
    GithubLink: () => (
      <Link variant="fancy" href="https://www.github.com" noExternalLinkIcon>
        <Box as="span" variant="layout.svg">
          <GrGithub />
        </Box>
        Get the code!
      </Link>
    ),
    ThemeToggle: (props) => <ColorModeToggle variant={props.variant} />,
  }

  return (
    <Box
      sx={{
        display: "flex",
        fontSize: "ui.8",
      }}
    >
      <FlexMenu
        className={className}
        variant="chrome"
        justify="space-between"
        height="header"
        data={menuData}
        componentMap={componentMap}
      />
    </Box>
  )
}

export default OverlayHeaderDesktop
