/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui";
import PropTypes from "prop-types";
import { Alert as ThemeUIAlert } from "theme-ui";
import { FaInfoCircle, FaCheckCircle } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { MdOutlineError } from "react-icons/md";

function Alert({ children, className, variant, title, ...rest }) {
  let icon = "";

  switch (variant) {
    case "info":
      icon = <FaInfoCircle />;
      break;
    case "success":
      icon = <FaCheckCircle />;
      break;
    case "warning":
      icon = <IoWarning />;
      break;
    case "error":
      icon = <MdOutlineError />;
      break;
    default:
      icon = null;
      break;
  }

  let t = title || variant; /* .toUpperCase() */

  return (
    <ThemeUIAlert
      className={className}
      variant={variant}
      sx={{
        p: "0.5em 0.5em",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      {...rest}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        {icon ? (
          <Box
            sx={{
              display: "flex",
              flex: icon ? "0 0 20px" : "1 1 auto",
              alignSelf: "center",
              fontSize: "20px",
            }}
          >
            {icon}
          </Box>
        ) : null}

        <Text as="p" variant="ui.6" sx={{ fontWeight: "normal" }}>
          {t}
        </Text>
      </Box>
      {children ? <Box mt="0.5em">{children}</Box> : <div />}
    </ThemeUIAlert>
  );
}

export default Alert;

Alert.propTypes = {
  /** React children */
  children: PropTypes.any,
  /** Theme-ui variant **/
  variant: PropTypes.string,
  /** Theme-ui className **/
  className: PropTypes.string,
};
