/** @jsx jsx */
import { jsx, Box } from "theme-ui";

const ListTitle = ({
  children,
  className,
  color,
  background,
  textAlign,
  size,
  as,
}) => {
  let styles = {};

  switch (size) {
    case "sm":
      styles = {
        fontSize: 3,
        fontWeight: "bold",
      };
      break;
    case "md":
      styles = {
        fontSize: 5,
        fontWeight: "light",
      };
      break;
    case "lg":
      styles = {
        fontSize: 7,
      };
      break;
    default:
      styles = {
        fontSize: 5,
      };
      break;
  }

  return (
    <Box
      className={className}
      as={as}
      sx={{
        color: color,
        bg: background,
        py: 4,
        textAlign: textAlign,
        ...styles,
      }}
    >
      {children}
    </Box>
  );
};

export default ListTitle;
