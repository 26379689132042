const sharedColors = {
  primary: "#949903", // Greens
  primaryMuted: "#b7bb36",
  primaryText: "#f6f6e5",
  primaryTextHover: "#ffffff",

  secondary: "#ff4c00", // Oranges
  secondaryMuted: "#ff6f32",
  secondaryText: "#ffede5",
  secondaryTextHover: "#ffffff",

  white: "#ffffff",
  whiteMuted: "#e8e8e9",
  whiteText: "#39393b",
  whiteTextHover: "#000000",

  black: "#000000",
  blackMuted: "#2b2a2c",
  blackText: "#dddddf",
  blackTextHover: "#ffffff",

  grey: "#908F95",
  greyMuted: "#a6a5aa",
  greyText: "#e8e8e9",
  greyTextHover: "#ffffff",

  info: "#005CFF",
  infoMuted: "#327cff",
  infoText: "#e5eeff",
  infoTextHover: "#ffffff",

  success: "#008000",
  successMuted: "#329932",
  successText: "#e5f2e5",
  successTextHover: "#ffffff",

  warning: "#ff7400",
  warningMuted: "#ff9d4c",
  warningText: "#fff1e5",
  warningTextHover: "#ffffff",

  error: "#ff0000",
  errorMuted: "#ff6666",
  errorText: "#ffe5e5",
  errorTextHover: "#ffffff",
};

export default sharedColors;
