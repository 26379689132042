import borders from "./borders";
import borderStyles from "./border-styles";
import borderWidths from "./border-widths";
import breakpoints from "./breakpoints";
import radii from "./radii";
import shadows from "./shadows";
import sizes from "./sizes";
import space from "./space";
import zIndices from "./z-indices";

import fontSizes from "./font-sizes";
import fontWeights from "./font-weights";
import fonts from "./fonts";
import letterSpacings from "./letter-spacings";
import lineHeights from "./line-heights";
import opacities from "./opacities";
import transitions from "./transitions";

export default {
  borders,
  borderStyles,
  borderWidths,
  breakpoints,
  radii,
  shadows,
  sizes,
  space,
  zIndices,
  fontSizes,
  fontWeights,
  fonts,
  letterSpacings,
  lineHeights,
  opacities,
  transitions,
};
