import typographyBaseStyles from "./typography-base-styles";

const { headingBaseStyles, headingStyles } = typographyBaseStyles;

const headingComponent = {
  // Styles applied to Header Component without variant
  ...headingBaseStyles,
  // Variants h1 through h6
  ...headingStyles,
};

export default headingComponent;
