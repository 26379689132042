import { Box } from "theme-ui";
import { SiteBranding, FlexMenu } from "@smerth/gatsby-theme-theme-ui";
import { AiFillCar } from "react-icons/ai";
import { BsApple } from "react-icons/bs";
import * as React from 'react';
export default {
  Box,
  SiteBranding,
  FlexMenu,
  AiFillCar,
  BsApple,
  React
};