import alerts from "./alerts";
import badges from "./badges";
import buttons from "./buttons";
import cards from "./cards";
import gradients from "./gradients";
import grids from "./grids";
import images from "./images";
import layout from "./layout";
import links from "./links";
import messages from "./messages";
import text from "./text";

export default {
  alerts,
  badges,
  buttons,
  cards,
  gradients,
  grids,
  images,
  layout,
  links,
  messages,
  text,
};
