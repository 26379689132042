/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import React, { useContext } from "react";
import { Link, OverlayContext } from "@smerth/gatsby-theme-theme-ui";

function MenuVertical({
  componentMap,
  className,
  data,
  indent,
  noPadding,
  noBorder,
  height,
  variant,
  justify,
  noExpand,
  wordBreak,
  ...rest
}) {
  const { setIsOverlayOpen } = useContext(OverlayContext);

  function handleClick() {
    setIsOverlayOpen(false);
  }

  function StyledLink({
    item,
    variant,
    noPadding,
    noExpand,
    height,
    justify,
    partiallyActive,
  }) {
    return (
      <Link
        variant={variant || "ui"}
        sx={{
          lineHeight: "normal",
          px: !noPadding ? 2 : "0px",
          py: `link.${height}`,
          display: !noExpand ? "flex" : "inline-block",
          flex: !noExpand ? "1 0" : "",
          justifyContent: justify,
          wordBreak: wordBreak,
        }}
        href={item.url}
        activeClassName="active"
        partiallyActive={partiallyActive}
        onClick={() => handleClick()}
      >
        {item.label}
      </Link>
    );
  }

  // TODO: Move ComponentFromMap to external component?
  function ComponentFromMap(props) {
    const { className, item } = props;
    if (typeof componentMap[item.component] !== "undefined") {
      const MappedComponent = componentMap[item.component];
      return (
        <MappedComponent
          className={className}
          {...props}
          sx={{
            px: !noPadding ? 2 : "0px",
            py: `link.${height}`,
          }}
        />
      );
    } else {
      return React.createElement(StyledLink, props);
    }
  }

  return (
    <Box
      as="ul"
      className={className}
      {...rest}
      sx={{ m: 0, ml: indent ? 3 : 0, p: 0 }}
    >
      {data.map((item, index) => {
        const props = {
          className,
          item,
          variant,
          noPadding,
          noExpand,
          justify,
          height,
        };
        return (
          <li
            key={index}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: justify,
              borderWidth: noBorder ? "" : "thin",
              borderColor: noBorder ? "" : `${variant}Muted`,
              borderStyle: noBorder ? "none" : "bottom",
              ":last-child": {
                borderStyle: "none",
              },
              listStyle: "none",
            }}
          >
            <ComponentFromMap {...props} />
          </li>
        );
      })}
    </Box>
  );
}

export default MenuVertical;
