/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui";

function SplitScreen({
  className,
  backgroundColorLeft,
  backgroundColorRight,
  componentLeft,
  componentRight,
}) {
  return (
    <Container
      className={className}
      sx={{
        display: ["block", "block", "grid"],
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      <Box
        sx={{
          bg: backgroundColorLeft,
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: ["none", "block"],
            flex: "1",
          }}
        />

        <Box
          sx={{
            flex: [
              "0 0 100%",
              "0 0 540px",
              "0 0 360px",
              "0 0 480px",
              "0 0 570px",
            ],
          }}
        >
          {componentLeft}
        </Box>
        <Box
          sx={{
            display: ["none", "block", "none"],
            flex: "1",
          }}
        />
      </Box>
      <Box
        sx={{
          bg: backgroundColorRight,
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: ["none", "block", "none"],
            flex: "1",
          }}
        />

        <Box
          sx={{
            flex: [
              "0 0 100%",
              "0 0 540px",
              "0 0 360px",
              "0 0 480px",
              "0 0 570px",
            ],
          }}
        >
          {componentRight}
        </Box>
        <Box
          sx={{
            display: ["none", "block"],
            flex: "1",
          }}
        />
      </Box>
    </Container>
  );
}

export default SplitScreen;
