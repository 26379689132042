/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

export const OverlayContext = React.createContext();

function SiteWrapper({ children }) {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState();

  //TODO: pass this in as a variable? take from theme?
  const mobile = "720px";

  // call back function to close overlay when moving to large screen
  const mediaQueryChange = (matches) => {
    if (!matches && isOverlayOpen) {
      setIsOverlayOpen(false);
    }
  };

  // runs a  media query returns a boolean based on the match
  // runs a callback function and passes in the boolean
  const isMobile = useMediaQuery(
    { query: `(max-width: ${mobile})` },
    undefined,
    mediaQueryChange,
  );

  // Media query cannot run on the server during SSR
  // isSmallScreen in null on page load to match the server
  // value updates when isMobile changes
  useEffect(() => {
    setIsSmallScreen(isMobile);
  }, [isMobile]);

  return (
    <OverlayContext.Provider
      value={{
        isOverlayOpen: isOverlayOpen,
        setIsOverlayOpen: setIsOverlayOpen,
        isSmallScreen: isSmallScreen,
        setIsSmallScreen: setIsSmallScreen,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
}

export default SiteWrapper;

SiteWrapper.propTypes = {
  children: PropTypes.node,
};
