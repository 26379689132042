import sharedColors from "./shared-colors";

// shared color are the same for each mode
// the key color for background and chrome is: #9c513a

const colors = {
  ...sharedColors,

  // text: "#4e281d",
  // background: "#f5edeb",
  // accent: "#ebdcd7", // a contrast color for UI elements
  // muted: "#e1cac3", // a faint contrast color for background
  // highlight: "#fff679", // highlight text <mark></mark>
  // codeblock: "#faf6f5",
  // codeBlockHighlight: "#eef1f6", // highlight text in codeblock

  text: "#4e281d",
  background: "#ffffff",
  accent: "#f8f2f1", // a contrast color for UI elements
  muted: "#f2e7e5", // a faint contrast color for background
  highlight: "#fff679", // highlight text <mark></mark>
  codeblock: "#f9f5f3",
  codeBlockHighlight: "#eef1f6", // highlight text in codeblock

  // use UI to match the page background, text and hover

  // ui: "#f5edeb", // matches background
  // uiMuted: "#ebdcd7", // matches accent
  // uiText: "#2e1811", // matches text
  // uiTextHover: "#000000",

  ui: "#ffffff", // matches background
  uiMuted: "#f8f2f1", // matches accent
  uiText: "#4e281d", // matches text
  uiTextHover: "#000000",

  chrome: "#9c513a", // Copper
  chromeMuted: "#af7361",
  chromeText: "#e1cac3",
  chromeTextHover: "#ffffff",

  modes: {
    dark: {
      // text: "#ebdcd7",
      // background: "#532b1e",
      // accent: "#644034", // a contrast color for UI elements
      // muted: "#3e2017", // a faint contrast color for background
      // highlight: "#cc2e14", // highlight text <mark></mark>
      // codeblock: "#371c14",
      // codeBlockHighlight: "#000000", // highlight text in codeblock

      text: "#ebdcd7",
      background: "#331a12",
      accent: "#462419", // a contrast color for UI elements
      muted: "#3f2016", // a faint contrast color for background
      highlight: "#cc2e14", // highlight text <mark></mark>
      codeblock: "#23120c",
      codeBlockHighlight: "#000000", // highlight text in codeblock

      // ui: "#532b1e", // matches background
      // uiMuted: "#644034", // matches accent
      // uiText: "#ebdcd7", // matches text
      // uiTextHover: "#ffffff",

      ui: "#331a12", // matches background
      uiMuted: "#462419", // matches accent
      uiText: "#ebdcd7", // matches text
      uiTextHover: "#ffffff",

      chrome: "#20110E",
      chromeMuted: "#371c14",
      chromeText: "#d7b9b0",
      chromeTextHover: "#ffffff",
    },
  },
};

export default colors;
