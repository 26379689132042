import { graphql, useStaticQuery } from "gatsby"

const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            slogan
            sitePrefix
            siteUrl
            description
            developerName
            developerGithubAccount
            developerNpmURL
          }
        }
      }
    `
  )

  return data.site.siteMetadata
}

export default useSiteMetadata
