/** @jsx jsx */
import { jsx, Box, Container, useThemeUI } from "theme-ui";
import Highlight from "prism-react-renderer";
import { extractThemeNode } from "@smerth/gatsby-theme-theme-ui";

function DisplayThemeNode({ codeString, language, theme, defaultProps }) {
  const { theme: themeUiTheme } = useThemeUI();
  const themeNode = extractThemeNode(themeUiTheme, codeString);
  const str = JSON.stringify(themeNode, null, 2);

  return (
    <Box
      sx={{
        position: "relative",
        px: 3,
        pt: "28px",
        pb: [3, 4],
        mt: 6,
        bg: "codeblock",
      }}
    >
      <Highlight {...defaultProps} code={str} language={language} theme={theme}>
        {({ className, tokens, getLineProps, getTokenProps }) => {
          return (
            <Box as="pre" className={className}>
              {language && <Container variant="language">{language}</Container>}
              {tokens.map((line, i) => {
                return (
                  <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                );
              })}
            </Box>
          );
        }}
      </Highlight>
    </Box>
  );
}

export default DisplayThemeNode;
