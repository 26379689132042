// https://type-scale.com/
// 1.250 - Major Third

const rem = [
  "0.41rem",
  "0.512rem",
  "0.64rem",
  "0.8rem",
  "1rem",
  "1.25rem",
  "1.563rem",
  "1.953rem",
  "2.441rem",
  "3.052rem",
  "3.815rem",
  "4.768rem",
  "5.96rem",
  "7.451rem",
  "9.313rem",
  "11.642rem",
];

const px = [
  "6.55px",
  "8.19px",
  "10.24px",
  "12.80px",
  "16.00px",
  "20.00px",
  "25.00px",
  "31.25px",
  "39.06px",
  "48.83px",
  "61.04px",
  "76.29px",
  "95.37px",
  "119.21px",
  "149.01px",
  "186.26px",
];

const majorThird = {
  rem,
  px,
};

export default majorThird;
