/** @jsx jsx */
import { jsx, Box, Container, Text, useColorMode, useThemeUI } from "theme-ui";
import React from "react";
import { defaultProps } from "prism-react-renderer";
import { useMDXScope } from "gatsby-plugin-mdx/context";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import light from "prism-react-renderer/themes/github";
import dark from "prism-react-renderer/themes/oceanicNext";
import {
  DisplayCode,
  DisplayThemeNode,
  DisplayHighlightedCode,
  CodeBlockWrapper,
} from "@smerth/gatsby-theme-theme-ui";

// To pass in all components from theme to react-live
// import and merge with useMDXScope()
// import * as smerthComponents from "@smerth/gatsby-theme-theme-ui";

// TODO: Extract to a more robust helper function to parse metastring into params
const getParams = (metastring = ``) => {
  const metaStr = metastring;
  let variableStr = "";
  let codeBlockType = "";
  let highlightStr = "";

  // does metastring contain a highlight lines obj
  if (metaStr.includes("} ")) {
    // target highlight str
    const a = metaStr.replace(/} /, "}###");
    // extract highlight str
    const [highlight = ``, b = ``] = a.split(`###`);
    // target type str
    const c = b.replace(/ /, "###");
    // extract type str
    const [type = ``, d = ``] = c.split(`###`);

    highlightStr = highlight;
    codeBlockType = type;
    variableStr = d;
    codeBlockType = type;
  } else {
    const a = metaStr.replace(/ /, "###");
    const [type = ``, b = ``] = a.split(`###`);
    variableStr = b;
    codeBlockType = type;
  }

  const obj = {};

  if (variableStr) {
    const a = variableStr.replace(/"/g, "'");
    // separate variables with comma
    const b = a.replace(/' /g, "', ");
    // remove single quotes
    const c = b.replace(/'/g, "");
    // split at the comma
    const d = c.split(", ");

    // map to extract values from either side of = and apply to obj
    d.map((pair) => {
      const [key, value] = pair.split("=");
      obj[key] = value;
    });
  } else {
    obj["title"] = "Example";
  }

  const meta = {
    highlight: highlightStr,
    type: codeBlockType,
    props: obj,
  };

  return meta;
};

function Code({ codeString, language, metastring, ...props }) {
  const context = useThemeUI();
  const preStyle = context.theme.styles.root.pre;

  // To add components from the theme to react-live scope, merge with useMDXScope
  const components = useMDXScope();
  const [colorMode] = useColorMode();

  // Swap prism theme for light/dark mode
  let prismTheme;

  switch (colorMode) {
    case "light":
      prismTheme = light;
      break;
    case "dark":
      prismTheme = dark;
      break;
    default:
      prismTheme = light;
  }

  if (props["live"]) {
    // A code block with "live" prop renders a ReactLive set of components
    const params = getParams(metastring);

    // TODO: Extract to a more robust helper function to build titles for CodeBlockWrapper
    const t = params.props?.title;
    const f = params.props?.file;
    const c = params.props?.component;

    let title = "";

    if (c) {
      title = (
        <span>
          {c} @"src/components/{c}"
        </span>
      );
    } else if (t || f) {
      title = (
        <React.Fragment>
          {t ? <span>{t}</span> : null}
          {f ? <span>@{f}</span> : null}
        </React.Fragment>
      );
    } else {
      title = null;
    }

    return (
      <CodeBlockWrapper type="live" title={title}>
        <LiveProvider
          code={codeString}
          scope={components}
          theme={prismTheme}
          {...params.props}
        >
          {/* Render component code provided to editor */}
          <Box p={3}>
            <LivePreview sx={{ my: [6, 7] }} />
            <Text
              as="p"
              variant="ui.7"
              sx={{
                p: 4,
                mb: [2, 3],
              }}
            >
              <Text as="span" variant="ui.6">
                Edit the props to see this component update live!
              </Text>
            </Text>
            {/* Render Code Editor */}
          </Box>
          <Box sx={{ position: "relative", pt: "18px", bg: "codeblock" }}>
            {language && <Container variant="language">{language}</Container>}
            <LiveEditor style={preStyle} />
          </Box>
          {/* Render Error Message */}
          <LiveError
            sx={{
              marginBottom: 0,
              padding: "10px",
              color: "errorText",
              bg: "error",
              width: "100%",
              overflow: "auto",
            }}
          />
        </LiveProvider>
      </CodeBlockWrapper>
    );
  } else if (props["display"]) {
    const params = getParams(metastring);
    // A code block with "display" prop renders a React component and highlighted code (not live)
    return (
      <DisplayCode
        components={components}
        codeString={codeString}
        language={language}
        theme={prismTheme}
        metastring={metastring}
        params={params}
        defaultProps={defaultProps}
      />
    );
  } else if (props["theme"]) {
    // A code block with "theme" prop renders a node from the theme-ui object as highlighted json
    return (
      <DisplayThemeNode
        codeString={codeString}
        language={language}
        theme={prismTheme}
        defaultProps={defaultProps}
      />
    );
  } else {
    // A code block with only a "language" prop renders standard highlighted code block.
    // Without a "language" props it renders unhighlighted code
    return (
      <DisplayHighlightedCode
        codeString={codeString}
        language={language}
        metastring={metastring}
        theme={prismTheme}
        defaultProps={defaultProps}
      />
    );
  }
}

export default Code;
