/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext } from "react";
import {
  Link,
  menuFlexFunctions,
  OverlayContext,
} from "@smerth/gatsby-theme-theme-ui";

function FlexMenuLink({
  variant,
  children,
  className,
  height,
  item,
  justify,
  expand,
  noExternalLinkIcon,
}) {
  const { setIsOverlayOpen } = useContext(OverlayContext);

  const { calculateLinkPadding } = menuFlexFunctions;

  function handleClick() {
    setIsOverlayOpen(false);
  }

  return (
    <Link
      className={className}
      variant={variant}
      noExternalLinkIcon={noExternalLinkIcon}
      href={item.url}
      activeClassName="active"
      partiallyActive={true}
      onClick={() => handleClick()}
      sx={{
        height: height,
        textDecoration: "none",
        display: "flex",
        flex: "1 0 auto",
        alignItems: "center",
        justifyContent: justify,
        paddingLeft: calculateLinkPadding(justify, expand),
        paddingRight: calculateLinkPadding(justify, expand),
      }}
    >
      {children || item.label}
    </Link>
  );
}

export default FlexMenuLink;
