import { graphql, useStaticQuery } from "gatsby"

const useComponentLinks = () => {
  const data = useStaticQuery(
    graphql`
      {
        allFile(
          filter: {
            extension: { eq: "mdx" }
            sourceInstanceName: { eq: "components" }
          }
          sort: { fields: fields___link___label, order: ASC }
        ) {
          nodes {
            id
            name
            fields {
              link {
                id
                label
                url
                slug
                color
                icon
                component
                publish
                description
              }
            }
          }
        }
      }
    `
  )

  // return data.allFile.nodes
  //   .map((item) => item.fields.link)
  //   .sort((a, b) => a.label > b.label)
  // const items = data.allFile.nodes
  //   .map((item) => item.fields.link)
  //   .sort((a, b) => a.label > b.label)

  const items = data.allFile.nodes.map((item) => item.fields.link)

  const obj = {
    id: "15920345823450238523",
    label: "Components",
    url: "",
    slug: "components",
    color: "inherit",
    icon: "components",
    component: "",
    publish: 1,
    description: "Description for Components section",
    items: items,
  }

  return obj
  /* return data.allFile.edges
    .map((edge) => edge.node.fields.link)
    .sort((a, b) => a.label > b.label) */
  // return data
}

export default useComponentLinks
