import { Box, Text } from "theme-ui";
import { MenuIcons } from "@smerth/gatsby-theme-theme-ui";
import { FaFacebook, FaTwitter, FaLinkedinIn, FaGithub, FaHome } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { GrInstagram } from "react-icons/gr";
import iconMenuData from "../../../../documentation/data/icon-menu-data.yaml";
import * as React from 'react';
export default {
  Box,
  Text,
  MenuIcons,
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaGithub,
  FaHome,
  IoLogoYoutube,
  GrInstagram,
  iconMenuData,
  React
};