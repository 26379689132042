/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui";
import { Tag } from "@smerth/gatsby-theme-theme-ui";

function TagCloud({ data, className, noCount, size, gap, variant = "ui" }) {
  return (
    <Box as="nav" className={className}>
      <Text
        as="ul"
        variant={`ui.${size}`}
        sx={{
          p: "0px",
          display: "flex",
          flexWrap: "wrap",
          gap: gap,
          listStyle: "none",
        }}
      >
        {data &&
          data.map((item, index) => {
            return (
              <Tag
                key={index}
                item={item}
                variant={variant}
                noCount={noCount}
              />
            );
          })}
      </Text>
    </Box>
  );
}

export default TagCloud;
