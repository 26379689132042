// These functions are used in multiple menus (horizontal and icon)
// in addition they are fed as props into components used in these menus, /// changes to these functions will affect multiple components!

function calculateFlexProperty(justify) {
  switch (justify) {
    case "flex-start":
    case "flex-end":
    case "start":
    case "end":
      return "";
    case "space-between":
    case "space-around":
      return "initial";
    default:
      return "";
  }
}

function calculateListItemPaddingLeft(justify, expand) {
  switch ((justify, expand)) {
    case "flex-start":
    case "start":
      return "0";

    case "flex-end":
    case "end":
      let lipLeft = expand ? "0" : [2, 3];
      return lipLeft;
    case "space-between":
    case "space-around":
      return "0";
    case "center":
      lipLeft = expand ? "0" : [1, 2];
      return lipLeft;
    default:
      return "0";
  }
}

function calculateListItemPaddingRight(justify, expand) {
  switch ((justify, expand)) {
    case "flex-start":
    case "start":
      let lipRight = expand ? "0" : [2, 3];
      return lipRight;
    case "flex-end":
    case "end":
      return "0";
    case "space-between":
    case "space-around":
      return "0";
    case "center":
      lipRight = expand ? "0" : [1, 2];
      return lipRight;
    default:
      return "0";
  }
}

function calculateLinkPadding(justify, expand) {
  switch ((justify, expand)) {
    case "flex-start":
    case "start":
    case "flex-end":
    case "end":
      let linkPadding = expand ? [2, 3] : "0";
      return linkPadding;
    case "space-between":
    case "space-around":
      return "0";
    case "center":
      linkPadding = expand ? [2, 3] : "0";
      return linkPadding;
    default:
      return "0";
  }
}

const menuFlexFunctions = {
  calculateFlexProperty,
  calculateListItemPaddingLeft,
  calculateListItemPaddingRight,
  calculateLinkPadding,
};

export default menuFlexFunctions;
