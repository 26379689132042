/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { useThemeUI } from "theme-ui";
import React from "react";
import { CodeBlockWrapper } from "@smerth/gatsby-theme-theme-ui";

function DisplayComponentVariants({ themeKey, component, stack }) {
  const { theme: themeUiTheme } = useThemeUI();
  const themeNode = themeUiTheme[themeKey];
  const arr = Object.keys(themeNode);

  return (
    <Box
      sx={{
        my: [2, 3],
        mb: [2, 3],
        display: !stack ? "flex" : "block",
        flexWrap: !stack ? "wrap" : "",
        justifyContent: !stack ? "flex-start" : "",
      }}
    >
      {arr.map((item) => {
        const props = { key: item, variant: item };
        const title = `Variant: ${item}`;
        return (
          <CodeBlockWrapper type="variants" key={title} title={title} margin>
            <Box p={3}>{React.createElement(component, props)}</Box>
          </CodeBlockWrapper>
        );
      })}
    </Box>
  );
}

export default DisplayComponentVariants;
