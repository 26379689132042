const lineHeights = [
  "0.79",
  "0.889",
  "1",
  "1.125",
  "1.266",
  "1.424",
  "1.602",
  "1.802",
  "2.027",
  "2.281",
  "2.566",
  "2.887",
  "3.247",
  "3.653",
  "4.11",
  "4.624",
];

export default lineHeights;
