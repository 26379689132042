module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/codebuild/output/src265819322/src/gatsby-theme-theme-ui/node_modules/@smerth/gatsby-theme-mdx/src/components/layouts/LayoutMdxDefault.js"},"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/codebuild/output/src265819322/src/gatsby-theme-theme-ui/node_modules/gatsby-remark-images","id":"32658888-e97e-563f-9132-b45b813fd0fb","name":"gatsby-remark-images","version":"6.24.0","modulePath":"/codebuild/output/src265819322/src/gatsby-theme-theme-ui/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/codebuild/output/src265819322/src/gatsby-theme-theme-ui/node_modules/gatsby-remark-table-of-contents","id":"b7a9c3d8-d545-559e-93e6-50af068bc955","name":"gatsby-remark-table-of-contents","version":"2.0.0","modulePath":"/codebuild/output/src265819322/src/gatsby-theme-theme-ui/node_modules/gatsby-remark-table-of-contents/dist/gatsby-remark-table-of-contents.cjs.js","pluginOptions":{"plugins":[],"exclude":"Table of Contents","tight":false,"ordered":false,"fromHeading":1,"toHeading":6,"className":"table-of-contents"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src265819322/src/gatsby-theme-theme-ui/demo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"injectColorFlashScript":true},
    },{
      plugin: require('../../packages/gatsby-theme-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
