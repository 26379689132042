import typographyBaseStyles from "./typography-base-styles";

const {
  uiTextBaseStyles,
  displayTextBaseStyles,
  headingStyles,
  siteNameBaseStyles,
  siteSloganBaseStyles,
} = typographyBaseStyles;

// ************
// TEXT COMPONENT
// ************

const textComponent = {
  // Default styles applied to HTML element
  // this object is also spread into styles.root for markdown
  root: {
    ...headingStyles,
    p: { variant: "text.paragraph.default" },
  },
  // Default styles applied to body element
  body: {},

  // Default Text Component Styles (without a variant)
  default: {
    variant: "text.paragraph.default",
  },

  paragraph: {
    base: {
      fontFamily: "serif",
      fontWeight: 3,
      letterSpacing: "0px",
      my: "1rem",
    },
    default: {
      variant: "text.paragraph.base",
      fontSize: [4, 4, 5],
      lineHeight: [6],
    },
    sm: {
      variant: "text.paragraph.base",
      fontSize: [3, 3, 4],
      lineHeight: 6,
    },
    md: {
      variant: "text.paragraph.base",
      fontSize: [4, 4, 5],
      lineHeight: 6,
    },
    lg: {
      variant: "text.paragraph.base",
      fontSize: [5, 5, 6],
      lineHeight: 7,
    },
    xl: {
      variant: "text.paragraph.base",
      fontSize: [6, 6, 7],
      lineHeight: 8,
    },
  },

  ui: {
    0: {
      ...uiTextBaseStyles,
      fontSize: "ui.0",
    },
    1: {
      ...uiTextBaseStyles,
      fontSize: "ui.1",
    },
    2: {
      ...uiTextBaseStyles,
      fontSize: "ui.2",
    },
    3: {
      ...uiTextBaseStyles,
      fontSize: "ui.3",
    },
    4: {
      ...uiTextBaseStyles,
      fontSize: "ui.4",
    },
    5: {
      ...uiTextBaseStyles,
      fontSize: "ui.5",
    },
    6: {
      ...uiTextBaseStyles,
      fontSize: "ui.6",
    },
    7: {
      ...uiTextBaseStyles,
      fontSize: "ui.7",
    },
    8: {
      ...uiTextBaseStyles,
      fontSize: "ui.8",
    },
    9: {
      ...uiTextBaseStyles,
      fontSize: "ui.9",
    },
    10: {
      ...uiTextBaseStyles,
      fontSize: "ui.10",
    },
    11: {
      ...uiTextBaseStyles,
      fontSize: "ui.11",
    },
    12: {
      ...uiTextBaseStyles,
      fontSize: "ui.12",
    },
    13: {
      ...uiTextBaseStyles,
      fontSize: "ui.13",
    },
    14: {
      ...uiTextBaseStyles,
      fontSize: "ui.14",
    },
  },

  display: {
    0: {
      ...displayTextBaseStyles,
      fontSize: ["display.2", null, "display.3", null, "display.4"],
    },
    1: {
      ...displayTextBaseStyles,
      fontSize: ["display.3", null, "display.4", null, "display.5"],
    },
    2: {
      ...displayTextBaseStyles,
      fontSize: ["display.4", null, "display.5", null, "display.6"],
    },
    3: {
      ...displayTextBaseStyles,
      fontSize: ["display.5", null, "display.6", null, "display.7"],
    },
    4: {
      ...displayTextBaseStyles,
      fontSize: ["display.6", null, "display.7", null, "display.8"],
    },
    5: {
      ...displayTextBaseStyles,
      fontSize: ["display.7", null, "display.8", null, "display.9"],
    },
    6: {
      ...displayTextBaseStyles,
      fontSize: [
        "display.6",
        "display.7",
        "display.8",
        "display.9",
        "display.10",
      ],
    },
    7: {
      ...displayTextBaseStyles,
      fontSize: [
        "display.7",
        "display.8",
        "display.9",
        "display.10",
        "display.11",
      ],
    },
    8: {
      ...displayTextBaseStyles,
      fontSize: [
        "display.8",
        "display.9",
        "display.10",
        "display.11",
        "display.12",
      ],
    },
    9: {
      ...displayTextBaseStyles,
      fontSize: [
        "display.9",
        "display.10",
        "display.11",
        "display.12",
        "display.13",
      ],
    },
    10: {
      ...displayTextBaseStyles,
      fontSize: [
        "display.10",
        "display.11",
        "display.12",
        "display.13",
        "display.14",
      ],
    },
    11: {
      ...displayTextBaseStyles,
      fontSize: [
        "display.11",
        "display.12",
        "display.13",
        "display.14",
        "display.15",
      ],
    },
  },

  siteName: {
    xs: {
      ...siteNameBaseStyles,
      fontSize: "24px",
    },
    sm: {
      ...siteNameBaseStyles,
      fontSize: "32px",
    },
    md: {
      ...siteNameBaseStyles,
      fontSize: "40px",
    },
    lg: {
      ...siteNameBaseStyles,
      fontSize: "60px",
    },
    xl: {
      ...siteNameBaseStyles,
      fontSize: "80px",
    },
  },

  siteSlogan: {
    xs: {
      ...siteSloganBaseStyles,
      fontSize: "14px",
    },
    sm: {
      ...siteSloganBaseStyles,
      fontSize: "16px",
    },
    md: {
      ...siteSloganBaseStyles,
      fontSize: "18px",
    },
    lg: {
      ...siteSloganBaseStyles,
      fontSize: "24px",
    },
    xl: {
      ...siteSloganBaseStyles,
      fontSize: "36px",
    },
  },
};

export default textComponent;
