/** @jsx jsx */
import { jsx, Box } from "theme-ui";

function TitleWithClippedGradient({ children, className, gradient }) {
  return (
    <Box
      className={className}
      as="span"
      sx={{
        backgroundImage: (t) => {
          const { [gradient]: value } = t.gradients;
          return value;
        },
        backgroundClip: "text",
        textFillColor: "transparent",
      }}
    >
      {children}
    </Box>
  );
}

export default TitleWithClippedGradient;
