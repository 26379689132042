/** @jsx jsx */
import { jsx, Box } from "theme-ui";

function Accordion({ children, className, direction }) {
  return (
    <Box
      as="div"
      className={className}
      sx={{
        display: "flex",
        flexDirection: ["column", "", direction], // vertical by default, optional horizontal on desktop
        gap: direction === "row" ? ["0px", "0px", 2] : "0px",
      }}
    >
      {children}
    </Box>
  );
}

export default Accordion;
