import typograhicScales from "../typography/typographic-scales";

const { textScale, uiScale, displayScale } = typograhicScales;

const fontSizes = {
  ...textScale,
  ui: {
    ...uiScale,
  },
  display: {
    ...displayScale,
  },
};

export default fontSizes;
