/** @jsx jsx */
import { jsx, Box, Link, Text } from "theme-ui";
// import { Link } from "gatsby";

function Pager({ data, label, noPager }) {
  const {
    humanPageNumber,
    itemsPerPage,
    pageNumber,
    previousPagePath,
    nextPagePath,
    numberOfItems,
  } = data;

  function Txt() {
    const labelText = numberOfItems === 1 ? label.singular : label.plural;

    let str =
      pageNumber === 0
        ? `Showing 1-${
            numberOfItems < itemsPerPage ? numberOfItems : itemsPerPage
          } of ${numberOfItems} ${labelText}`
        : `Showing ${itemsPerPage * pageNumber + 1}-${
            itemsPerPage * humanPageNumber < numberOfItems
              ? itemsPerPage * humanPageNumber
              : numberOfItems
          } of ${numberOfItems} ${labelText}`;

    return (
      <Text as="p" variant="ui.4">
        {str}
      </Text>
    );
  }

  return (
    <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
      {!previousPagePath || noPager ? (
        <div />
      ) : (
        <Link variant="ui" href={`${previousPagePath}`}>
          &#5176;
        </Link>
      )}
      <Txt />

      {!nextPagePath || noPager ? (
        <div />
      ) : (
        <Link
          variant="ui"
          href={`${nextPagePath}/`}
          // sx={{ color: "inherit", textDecoration: "none" }}
        >
          &#5171;
        </Link>
      )}
    </Box>
  );
}

export default Pager;
