import minorSecond from "./1-067-minor-second";
import majorSecond from "./1-125-major-second";
// import minorThird from "./1-200-minor-third";
import majorThird from "./1-250-major-third";

// TODO: export a function that takes in "rem" || "px" and returns correct object instead of using a separate file for each scale

const uiScale = minorSecond.rem; // For elements composing the ui
// const textScale = majorSecond.rem; // For markdown and html
const textScale = majorSecond.rem; // For markdown and html
const displayScale = majorThird.rem; // For marketing text

const typograhicScales = {
  uiScale,
  textScale,
  displayScale,
};

export default typograhicScales;
