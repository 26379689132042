/** @jsx jsx */
import { Message as M, jsx } from "theme-ui";

const Message = ({ children, variant = "primary", ...rest }) => (
  <M variant={variant} {...rest}>
    {children}
  </M>
);

export default Message;
