/** @jsx jsx */
import { jsx, Text } from "theme-ui";

function Copyright({ align, className, title, variant = "ui.3" }) {
  return (
    <Text
      as="p"
      variant={variant}
      className={className}
      sx={{ textAlign: align }}
    >
      © {new Date().getFullYear()} - {title} - All Rights Reserved
    </Text>
  );
}

export default Copyright;
