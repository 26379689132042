/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { useThemeUI } from "theme-ui";
import React from "react";
import {
  extractThemeNode,
  CodeBlockWrapper,
} from "@smerth/gatsby-theme-theme-ui";

function DisplayThemeScale({ className, themeKey, component, direction }) {
  const { theme: themeUiTheme } = useThemeUI();
  const themeNode = extractThemeNode(themeUiTheme, themeKey);
  const arr = Object.keys(themeNode);

  return (
    <Box
      className={className}
      sx={{
        my: [2, 3],
        mb: [2, 3],
        display: "flex",
        flexWrap: "wrap",
        flexDirection: direction || "row",
        justifyContent: "flex-start",
      }}
    >
      {arr.map((item) => {
        const props = { value: themeNode[item] };
        const title = `${item}: ${themeNode[item]}`;
        return (
          <CodeBlockWrapper
            type="array"
            key={title}
            title={title}
            align="flex-start"
            margin
          >
            <Box p={3}>{React.createElement(component, props)}</Box>
          </CodeBlockWrapper>
        );
      })}
    </Box>
  );
}

export default DisplayThemeScale;
