// variant for the ThemeUI Message component

const sharedMessageStyles = {
  variant: "text.ui.5",
  borderLeftWidth: "6px",
  borderRadius: "sm",
};

const messages = {
  primary: {
    ...sharedMessageStyles,
    color: "primary",
    bg: "white",
    borderLeftColor: "primary",
  },
  secondary: {
    ...sharedMessageStyles,
    color: "secondary",
    bg: "white",
    borderLeftColor: "secondary",
  },
  white: {
    ...sharedMessageStyles,
    color: "black",
    bg: "white",
    borderLeftColor: "gray",
  },
  black: {
    ...sharedMessageStyles,
    color: "white",
    bg: "black",
    borderLeftColor: "white",
  },
};

export default messages;
