const borderStyles = {
  solid: "solid",
  dashed: "dashed",
  dotted: "dotted",
  right: "none solid none none",
  left: "none none none solid",
  top: "solid none none none",
  bottom: "none none solid none",
  topLeft: "solid none none solid",
  topRight: "solid solid none none",
  topBottom: "solid none solid none",
};

export default borderStyles;
