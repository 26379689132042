// import { lighten } from "@theme-ui/color";
import { alpha } from "@theme-ui/color";

const gradients = {
  highlight: (t) => `
  linear-gradient(
    to right,
    ${alpha("highlight", 1)(t)},
    ${alpha("highlight", 0.5)(t)}
    )
  `,
  grey: (t) =>
    `linear-gradient(90deg, ${t.colors.grey} 0%, ${t.colors.greyMuted} 100%)`,
  primary: (t) =>
    `linear-gradient(45deg, ${t.colors.primary} 0%, ${t.colors.primaryMuted} 100%)`,
  secondary: (t) =>
    `linear-gradient(90deg, ${t.colors.secondary} 0%, ${t.colors.secondaryMuted} 100%)`,
  alpha: (t) => `
    linear-gradient(
      to bottom,
      ${alpha("primary", 0.6)(t)},
      ${alpha("secondary", 0.6)(t)}
    )
  `,
  alphaPrimary: (t) => `
    linear-gradient(
      to bottom,
      ${alpha("primary", 0.6)(t)},
      ${alpha("white", 0.9)(t)}
    )
  `,
  alphaSecondary: (t) => `
    linear-gradient(
      to bottom,
      ${alpha("secondary", 0.6)(t)},
      ${alpha("white", 0.9)(t)}
    )
    `,
  action: "linear-gradient(315deg, #fff000 0%, #ed008c 65%)",
  rainbow:
    "linear-gradient( 127deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%)",
  pastel:
    "linear-gradient(90deg,#ffa745,#fe869f 30%,#ef7ac8 45%,#a083ed 70%,#43aeff 85%)",
};

export default gradients;
