/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext } from "react";
import { OverlayContext } from "@smerth/gatsby-theme-theme-ui";
import { MdMenu, MdClose } from "react-icons/md";

function OverlayToggle(props) {
  const { justify, expand, linkPadding, className, size, variant } = props;
  const { isOverlayOpen, setIsOverlayOpen } = useContext(OverlayContext);

  function toggle() {
    setIsOverlayOpen(!isOverlayOpen);
  }

  return (
    <div
      className={className}
      sx={{
        display: "flex",
        alignItems: "center",
        paddingLeft: linkPadding && linkPadding(justify, expand) / 2,
        paddingRight: linkPadding && linkPadding(justify, expand) / 2,
      }}
    >
      {isOverlayOpen ? (
        <MdClose
          aria-label="Toggle Menu"
          onClick={() => toggle()}
          sx={{
            color: `${variant}Text`,
            width: "inherit",
            height: "inherit",
            width: size,
            height: size,
            transition: "ease color 250ms",
            "&:hover": { color: `${variant}TextHover`, cursor: "pointer" },
          }}
        />
      ) : (
        <MdMenu
          aria-label="Toggle Menu"
          onClick={() => toggle()}
          sx={{
            color: `${variant}Text`,
            width: "inherit",
            height: "inherit",
            width: size,
            height: size,
            transition: "ease color 250ms",
            "&:hover": { color: `${variant}TextHover`, cursor: "pointer" },
          }}
        />
      )}
    </div>
  );
}

export default OverlayToggle;
