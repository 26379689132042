/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import React from "react";
import { CodeBlockWrapper } from "@smerth/gatsby-theme-theme-ui";

function DisplayComponentArray({ component, propsArray }) {
  const propName = Object.keys(propsArray)[0];

  return (
    <Box
      sx={{
        my: [2, 3],
        mb: [2, 3],
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}
    >
      {propsArray[propName].map((item) => {
        const props = { ...propsArray };
        props["key"] = item;
        props[propName] = item;
        const title = `${propName}="${item}"`;

        return (
          <CodeBlockWrapper type="array" key={title} title={title} margin>
            <Box p={3}>{React.createElement(component, props)}</Box>
          </CodeBlockWrapper>
        );
      })}
    </Box>
  );
}

export default DisplayComponentArray;
