/** @jsx jsx */
import { jsx } from "theme-ui";
// import React from "react";
import { preToCodeBlock } from "mdx-utils";
import { Code } from "@smerth/gatsby-theme-theme-ui";

function Pre(preProps) {
  const props = preToCodeBlock(preProps);

  // if there's a codeString and some props, we passed the test
  if (props) {
    return <Code {...props} />;
  } else {
    // it's possible to have a pre without a code in it
    return <pre {...preProps} />;
  }
}

export default Pre;
