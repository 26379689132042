// Use for Container component

const layout = {
  // this is the default style for a container
  container: {
    position: "relative", // give a container its own stacking context
  },
  // Use for constrained page
  constrainedBase: {
    position: "relative", // give a container its own stacking context
    maxWidth: ["100%", "540px", "720px", "960px", "1140px"],
    marginLeft: "auto", // center content
    marginRight: "auto", // center content
    // px: [4, 4, 0], // padding to keep content away from browser scrollbars
  },
  // Use for constrained page
  constrained: {
    variant: "layout.constrainedBase",
    px: [4, 4, 0], // padding to keep content away from browser scrollbars
  },
  // Use for constrained page
  constrainedNoPadding: {
    variant: "layout.constrainedBase",
    px: [0, 0, 0], // no padding to keep content away from browser scrollbars
  },
  // Use to constrain width of a div
  constrainedByPercent: {
    maxWidth: ["100%", "90%", "85%", "80%", "75%"],
    marginLeft: "auto", // center content
    marginRight: "auto", // center content
  },
  // Use for fluid page (as opposed to constrained)
  fluid: {
    position: "relative", // give a container its own stacking context
    maxWidth: "100%",
    px: 4, // padding to keep content away from browser scrollbars
    flex: "1 1 auto",
  },
  // Use for sections, panels, etc...
  centered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // height: "100%",
  },
  // Use for sections, panels, etc...
  alignCenter: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // height: "100%",
  },
  // Use for article / sidebar layouts
  article: {
    pt: 4,
    pb: 0,
    pl: [0, 0, 2, 2, 3],
    borderWidth: "thin",
    borderColor: "accent",
    borderStyle: ["top", "top", "topLeft"],
  },
  //
  svg: {
    display: "inline-flex",
    alignItems: "center",
    alignSelf: "center",
    svg: {
      flex: "0 0 auto",
    },
  },
  // Use for article / sidebar layouts
  aside: {
    py: 0,
    pl: [0, 0, 2, 2, 3],
    borderWidth: [null, null, "thin"],
    borderColor: [null, null, "accent"],
    borderStyle: [null, null, "topLeft"],
  },
  // language tag used in codeblocks
  language: {
    fontFamily: "sans",
    fontSize: "16px",
    position: "absolute",
    m: 0,
    px: 3,
    // py: 1,
    width: "auto",
    top: "0",
    right: 2,
    color: "white",
    bg: "primary",
    border: "1px solid transparent",
    borderRadius: "0px 0px 5px 5px",
  },
  // https://keithclark.co.uk/articles/pure-css-parallax-websites/
  // TODO: annotate the css
  // TODO: introduce variables and calc() values from them
  // TODO: rename elements if necessary, for clarity
  // TODO: add property for hero group which uses variable for header height
  // TODO: add variables to the sizes or space? scale
  // TODO: fetch variables from screen
  parallax: {
    wrapper: {
      perspective: "1px",
      height: "100vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    group: {
      position: "relative",
      height: "100vh",
      transformStyle: "preserve-3d",
      // use this transform when debugging to remove layers
      // transform: "translate3d(700px, 0, -800px) rotateY(30deg)",
    },
    layer: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    foreground: {
      variant: "layout.parallax.layer",
      transform: "translateZ(0)",
    },
    background: {
      variant: "layout.parallax.layer",
      height: "100vh",
      transform: "translateZ(-1px) scale(2)",
      zIndex: -1, // do you need this?
      "&:after": {
        // gatsby-background-image adds this css,
        // but for images that are fetched by another means...
      },
    },
    plain: {
      position: "relative", // set a stacking context so the zIndex works
      minHeight: "100vh",
      background: "background", // set in the component
      zIndex: 5,
    },
    // hero is a special case for parallax group at top of page which needs to account for the height of the site header.
    hero: {},
  },
};

export default layout;
