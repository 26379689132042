import { Box } from "theme-ui";
import { GrGithub } from "react-icons/gr";
import { IoMegaphone } from "react-icons/io5";
import { HiExternalLink } from "react-icons/hi";
import { Button } from "@smerth/gatsby-theme-theme-ui";
import * as React from 'react';
export default {
  Box,
  GrGithub,
  IoMegaphone,
  HiExternalLink,
  Button,
  React
};