import { Box, Text, Link as ThemeUILink } from "theme-ui";
import { Link as GatsbyLink } from "theme-ui";
import { Alert, Link } from "@smerth/gatsby-theme-theme-ui";
import { GrGithub } from "react-icons/gr";
import * as React from 'react';
export default {
  Box,
  Text,
  ThemeUILink,
  GatsbyLink,
  Alert,
  Link,
  GrGithub,
  React
};