/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import React from "react";
import { LiveProvider, LivePreview } from "react-live";
import {
  CodeBlockWrapper,
  DisplayHighlightedCode,
} from "@smerth/gatsby-theme-theme-ui";

function DisplayCode({
  params,
  metastring,
  codeString,
  components,
  theme,
  language,
  defaultProps,
}) {
  const title =
    params.props?.title || params.props?.file ? (
      <React.Fragment>
        {params.props?.title ? <span>{params.props?.title}</span> : "Example"}
        {params.props?.file ? <span>@{params.props?.file}</span> : null}
      </React.Fragment>
    ) : null;

  return (
    <CodeBlockWrapper type="display" title={title}>
      <LiveProvider code={codeString} scope={components} theme={theme}>
        <Box p={3}>
          <LivePreview sx={{ my: [6, 7] }} />
        </Box>
      </LiveProvider>
      <DisplayHighlightedCode
        codeString={codeString}
        language={language}
        metastring={metastring}
        theme={theme}
        defaultProps={defaultProps}
      />
    </CodeBlockWrapper>
  );
}

export default DisplayCode;
