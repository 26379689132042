import { useContext } from "react";
import { OverlayContext } from "@smerth/gatsby-theme-theme-ui";

function SwapOnMobile({ mobileComponent, desktopComponent }) {
  const { isSmallScreen } = useContext(OverlayContext);

  return isSmallScreen ? mobileComponent : desktopComponent;
}

export default SwapOnMobile;
