import { graphql, useStaticQuery } from "gatsby"

const useDesktopMenuData = () => {
  const data = useStaticQuery(
    graphql`
      {
        menuYaml(title: { eq: "desktopMenu" }) {
          id
          title
          items {
            id
            label
            url
            publish
            component
            variant
            color
            description
          }
        }
      }
    `
  )
  const items = data.menuYaml.items.filter((item) => item.publish === true)
  return items
}

export default useDesktopMenuData
