const alerts = {
  success: {
    color: "successText",
    bg: "success",
  },
  warning: {
    color: "warningText",
    bg: "warning",
  },
  error: {
    color: "errorText",
    bg: "error",
  },
  info: {
    color: "infoText",
    bg: "info",
  },
  muted: {
    color: "text",
    bg: "muted",
  },
  accent: {
    color: "text",
    bg: "accent",
  },
};

export default alerts;
