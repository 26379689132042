import { darken } from "@theme-ui/color";

const buttons = {
  ui: {
    color: "uiText",
    bg: "ui",
    "&:hover": {
      color: "uiTextHover",
      bg: "background",
    },
  },
  accent: {
    color: "uiText",
    bg: "accent",
    "&:hover": {
      color: "uiTextHover",
      bg: "accent",
    },
  },
  chrome: {
    color: "chromeText",
    bg: "chrome",
    "&:hover": {
      color: "#ffffff",
      bg: "chrome",
    },
  },
  primary: {
    color: "primaryText",
    bg: "primary",
    "&:hover": {
      color: "primaryText",
      bg: (t) => `${darken("primary", 0.05)(t)}`,
    },
  },
  secondary: {
    color: "secondaryText",
    bg: "secondary",
    "&:hover": {
      color: "secondaryText",
      bg: (t) => `${darken("secondary", 0.05)(t)}`,
    },
  },
  black: {
    color: "blackText",
    bg: "black",
    "&:hover": {
      color: "blackText",
      bg: (t) => `${darken("black", 0.05)(t)}`,
    },
  },
  grey: {
    color: "greyText",
    bg: "grey",
    "&:hover": {
      color: "greyText",
      bg: (t) => `${darken("grey", 0.05)(t)}`,
    },
  },
  white: {
    color: "whiteText",
    bg: "white",
    "&:hover": {
      color: "whiteText",
      bg: (t) => `${darken("white", 0.05)(t)}`,
    },
  },
  info: {
    color: "infoText",
    bg: "info",
    "&:hover": {
      color: "infoText",
      bg: (t) => `${darken("info", 0.05)(t)}`,
    },
  },
  success: {
    color: "successText",
    bg: "success",
    "&:hover": {
      color: "successText",
      bg: (t) => `${darken("success", 0.05)(t)}`,
    },
  },
  warning: {
    color: "warningText",
    bg: "warning",
    "&:hover": {
      color: "warningText",
      bg: (t) => `${darken("warning", 0.05)(t)}`,
    },
  },
  error: {
    color: "errorText",
    bg: "error",
    "&:hover": {
      color: "errorText",
      bg: (t) => `${darken("error", 0.05)(t)}`,
    },
  },
  disabled: {
    cursor: "normal",
    color: "grey",
    bg: "accent",
    "&:hover": {
      color: "grey",
      bg: "accent",
    },
  },
  transparent: {
    cursor: "normal",
    color: "inherit",
    bg: "transparent",
    "&:hover": {
      color: "inherit",
      bg: "transparent",
    },
  },
  gradient: {
    color: "white",
    backgroundColor: "chrome",
    backgroundImage: (t) => t.gradients.action,
    border: "1px solid",
    borderColor: "chromeMuted",
    borderRadius: "md",
    px: 5,
    py: 1,
    fontFamily: "sans",
    fontWeight: "light",
    fontSize: "inherit",
    lineHeight: "normal",
    textDecoration: "none",
    display: "flex",
    gap: 1,
    alignItems: "center",
    "&>span": { mx: 3 },
    "&:hover": {
      color: "chrome",
      backgroundColor: "#ffffff",
      backgroundImage: "none",
      border: "1px solid",
      borderColor: "chrome",
    },
  },
};

export default buttons;
