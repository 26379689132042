/** @jsx jsx */
import { jsx, Box, Button as ThemeUiButton } from "theme-ui";

// Reference: https://react.school/ui/button
function Button({
  label,
  icon,
  right,
  children,
  className,
  onClick,
  variant = "primary",
  size,
  shadow,
  title,
  disabled,
}) {
  let fontSize;

  switch (size) {
    case "lg":
      fontSize = 7;
      break;
    case "md":
      fontSize = 5;
      break;
    case "sm":
      fontSize = 3;
      break;
    default:
      fontSize = "inherit";
      break;
  }

  return (
    <ThemeUiButton
      className={className}
      sx={{
        display: "flex",
        flexDirection: right ? "row-reverse" : "row",
        alignItems: "center",
        boxShadow: shadow
          ? (theme) => `0px 1px 2px ${theme.colors.black}`
          : "0",
        fontSize: fontSize,
        py: "5px",
        px: icon && !label ? "5px" : "10px",
        borderRadius: "5px",
        border: "none",
        outline: "0", // remove outline on focus
        // textTransform: "uppercase",
        marginTop: "0px",
        marginBottom: "0px",
        cursor: "pointer",
        transition: "ease all 250ms",
        "&:disabled": {
          cursor: "default",
          opacity: "0.7",
        },
        svg: {
          mr: label && !right ? [2, 3] : "0",
          ml: label && right ? [2, 3] : "0",
        },
        variant: disabled ? `buttons.disabled` : `buttons.${variant}`,
      }}
      disabled={disabled ? true : null}
      onClick={onClick}
      title={title}
    >
      {icon}
      <Box as="div" sx={{ display: "flex", alignItems: "center" }}>
        {children}
        {label}
      </Box>
    </ThemeUiButton>
  );
}

export default Button;
