// TODO: use es6 destructuring to extract a node from dot notation string  obj.node.node ...

const extractThemeNode = (theme, str) => {
  let newNode = theme;
  const arr = str.split(".");

  // const themeNode = (arr) => {
  //   const n = arr.shift();
  //   console.log("n: ", n);
  // };

  // themeNode(arr);

  arr.forEach(function (item) {
    newNode = newNode[item];
  });
  return newNode;
};

export default extractThemeNode;
