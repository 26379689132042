/** @jsx jsx */
import { jsx, Box, Link as ThemeUILink } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";
import { FiExternalLink } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import React from "react";

const InternalLink = ({
  children,
  className,
  to,
  partiallyActive,
  ...rest
}) => {
  return (
    <ThemeUILink
      className={className}
      as={GatsbyLink}
      to={to}
      partiallyActive={partiallyActive}
      {...rest}
    >
      {children}
    </ThemeUILink>
  );
};

const ExternalLinkContent = ({ children, email, tel, noExternalLinkIcon }) => {
  if (email && !noExternalLinkIcon) {
    return (
      <>
        <Box as="span" sx={{ display: "flex", alignSelf: "center" }}>
          <HiOutlineMail sx={{ fontSize: "100%" }} />
        </Box>

        {children}
      </>
    );
  } else if (tel && !noExternalLinkIcon) {
    return (
      <>
        <Box
          as="span"
          variant="layout.svg"
          sx={{ display: "flex", alignSelf: "center" }}
        >
          <BsTelephone sx={{ fontSize: "80%" }} />
        </Box>
        {children}
      </>
    );
  } else if (noExternalLinkIcon) {
    return <>{children}</>;
  } else {
    return (
      <>
        {children}
        <Box
          as="span"
          variant="layout.svg"
          sx={{ alignItems: "baseline", gap: 1 }}
        >
          <FiExternalLink sx={{ fontSize: "80%" }} />
        </Box>
      </>
    );
  }
};

function Link(props) {
  const {
    activeClassName,
    children,
    href,
    className,
    noExternalLinkIcon,
    partiallyActive,
    ...rest
  } = props;

  let email = href.startsWith("mailto:");
  let tel = href.startsWith("tel:");
  let internal = href.startsWith("/") && !href.includes("//");

  if (internal) {
    return (
      <InternalLink
        className={className}
        activeClassName={activeClassName}
        to={href}
        partiallyActive={partiallyActive}
        sx={{ display: "inline-flex", alignItems: "stretch", gap: "2px" }}
        {...rest}
      >
        {children}
      </InternalLink>
    );
  } else {
    return (
      <ThemeUILink
        className={className}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ display: "inline-flex", alignItems: "stretch", gap: "2px" }}
        {...rest}
      >
        <ExternalLinkContent
          email={email}
          tel={tel}
          noExternalLinkIcon={noExternalLinkIcon}
        >
          {children}
        </ExternalLinkContent>
      </ThemeUILink>
    );
  }
}

export default Link;
