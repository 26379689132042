// TODO: Remove Oswald
// TODO: install "@fontsource/ibm-plex-sans-condensed": "^4.5.7"
// TODO: Remove fonts from personal site
import "@fontsource/merriweather";
import "@fontsource/jetbrains-mono";
import "@fontsource/ibm-plex-sans-condensed";
import "@fontsource/open-sans-condensed";

const fonts = {
  serif: "Merriweather",
  sans: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  monospace: "JetBrains Mono",
  display: "'IBM Plex Sans Condensed', sans-serif",
  condensed: "Open Sans Condensed",
};

export default fonts;
