import { Box, Container } from "theme-ui";
import { DisplayComponentVariants, FlexMenu, FlexMenuLink, SiteBranding, SiteLogo, SwapOnMobile } from "@smerth/gatsby-theme-theme-ui";
import { BsApple } from "react-icons/bs";
import { MdArrowForwardIos } from "react-icons/md";
import menuData from "../../../../documentation/data/menu.yaml";
import bigMenuData from "../../../../documentation/data/big-menu.yaml";
import * as React from 'react';
export default {
  Box,
  Container,
  DisplayComponentVariants,
  FlexMenu,
  FlexMenuLink,
  SiteBranding,
  SiteLogo,
  SwapOnMobile,
  BsApple,
  MdArrowForwardIos,
  menuData,
  bigMenuData,
  React
};