import { graphql, useStaticQuery } from "gatsby"

const useHelperLinks = () => {
  const data = useStaticQuery(
    graphql`
      {
        allFile(
          filter: {
            extension: { eq: "mdx" }
            sourceInstanceName: { eq: "helpers" }
          }
          sort: { fields: fields___link___label, order: ASC }
        ) {
          nodes {
            id
            name
            fields {
              link {
                id
                label
                url
                slug
                color
                icon
                component
                publish
                description
              }
            }
          }
        }
      }
    `
  )

  // return data.allFile.nodes
  //   .map((item) => item.fields.link)
  //   .sort((a, b) => a.label > b.label)

  const items = data.allFile.nodes.map((item) => item.fields.link)
  // .sort((a, b) => a.label > b.label)

  const obj = {
    id: "1329582346349213021",
    label: "Helpers",
    url: "",
    slug: "helpers",
    color: "inherit",
    icon: "helpers",
    component: "",
    publish: 1,
    description:
      "Helpers - hooks and functions to helper build a design system.",
    items: items,
  }

  return obj
}

export default useHelperLinks
