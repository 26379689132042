/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui";
import { useThemeUI } from "theme-ui";
import React from "react";
import { CodeBlockWrapper } from "@smerth/gatsby-theme-theme-ui";

function DisplayCSSPropertyMatrix(props) {
  // Should be primaryProp, secondaryProp
  const {
    component,
    cssPropertyOne,
    themeKeyOne,
    cssPropertyTwo,
    themeKeyTwo,
    width,
    gap,
  } = props;

  const { theme: themeUiTheme } = useThemeUI();

  const primaryNode = themeUiTheme[themeKeyOne];
  const secondaryNode = themeUiTheme[themeKeyTwo];

  const primaryArray = Object.keys(primaryNode);
  const secondaryArray = Object.keys(secondaryNode);

  return (
    <div>
      <Box
        sx={{
          my: [2, 3],
          mb: [2, 3],
          display: "flex",
          flexDirection: "column",
        }}
      >
        {primaryArray.map((item) => {
          const props = {};
          props[cssPropertyOne] = primaryNode[item];
          // const title = `${cssPropertyTwo}: ${item} (${secondaryNode[item]})`;
          const title = `${cssPropertyOne}: ${item}`;

          return (
            <CodeBlockWrapper
              type="matrix"
              key={title}
              title={title}
              align="flex-start"
            >
              <Box
                sx={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  gap: gap ? gap : "0px",
                }}
              >
                {secondaryArray.map((item) => {
                  props[cssPropertyTwo] = secondaryNode[item];
                  // const title = `${cssPropertyOne}: ${item} (${primaryNode[item]})`;
                  const title = `${cssPropertyTwo}: ${item}`;
                  return (
                    <Box
                      key={title}
                      sx={{
                        border: (theme) => `1px solid ${theme.colors.accent}`,
                        // border: "1px solid red",
                        width: width ? width : "100%",
                        p: 2,
                        mb: 5,
                        /* bg: "red", */
                      }}
                    >
                      <Text as="h1" variant="ui.4">
                        {title}
                      </Text>
                      {React.createElement(component, props)}
                    </Box>
                  );
                })}
              </Box>
            </CodeBlockWrapper>
          );
        })}
      </Box>
    </div>
  );
}

export default DisplayCSSPropertyMatrix;
