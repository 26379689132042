/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import {
  FlexMenuLink,
  SiteName,
  SiteSlogan,
} from "@smerth/gatsby-theme-theme-ui";

function SiteBranding(props) {
  const {
    className,
    variant,
    children,
    item,
    siteName,
    siteSlogan,
    size,
    hideName,
    hideSlogan,
    stack,
    center,
    noLink,
  } = props;

  function Wrapper(props) {
    const { children, noLink } = props;
    return !noLink ? (
      <FlexMenuLink
        className={className}
        variant={variant}
        item={item}
        justify="center"
        sx={{
          display: stack ? "block" : "flex",
          gap: hideName && hideSlogan ? "0px" : 2,
          height: "100%",
          "&.active": {
            textDecoration: "none",
          },
        }}
      >
        {children}
      </FlexMenuLink>
    ) : (
      <Box
        className={className}
        sx={{
          display: stack ? "block" : "flex",
          gap: 2,
          "&.active": {
            textDecoration: "none",
          },
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Wrapper noLink={noLink}>
      {children}
      <Box>
        <SiteName
          name={siteName}
          variant={`siteName.${size}`}
          center={center}
          hide={hideName}
          sx={{
            textAlign: center ? "center" : "inherit",
          }}
        />
        <SiteSlogan
          slogan={siteSlogan}
          variant={`siteSlogan.${size}`}
          hide={hideSlogan}
          sx={{
            textAlign: center ? "center" : "inherit",
          }}
        />
      </Box>
    </Wrapper>
  );
}

export default SiteBranding;
