// import { PageTitle } from "@smerth/gatsby-theme-theme-ui";
import fonts from "../scales/fonts";
import typographyBaseStyles from "../typography/components/typography-base-styles";

const { headingStyles } = typographyBaseStyles;

// Styles applied to markdown files
const styles = {
  // root styles are applied to HTML body
  // the root styles are overridden for MDX processed HTML below
  root: {
    variant: "text.root",
    pre: {
      fontFamily: fonts.monospace, // pre is applied to LiveEditor which is not theme aware, hence the fonts import!
      fontSize: "17px",
      backgroundColor: "transparent",
      overflow: "auto",
      // TODO: How to handle these safari properties in js
      "::WebkitScrollbar": {
        webkitAppearance: "none",
        width: "7px",
      },
      "::WebkitScrollbarThumb": {
        borderRadius: "4px",
        backgroundColor: "rgba(0, 0, 0, .5)",
        boxShadow: "0 0 1px rgba(255, 255, 255, .5)",
      },
      // overflowX: "hidden",
    },
  },
  // following styles apply to Markdown
  // ...headingStyles,
  // h1-h6 are assigned components in gatsby-plugin-theme-ui > components.js
  // h1: headingStyles.h1,
  h2: headingStyles.h2,
  h3: headingStyles.h3,
  h4: headingStyles.h4,
  h5: headingStyles.h5,
  h6: headingStyles.h6,
  p: {
    variant: "text.paragraph.default",
    img: { maxWidth: "100%" },
    "&:first-of-type": { variant: "text.paragraph.large" },
  },
  a: {
    variant: "links.mdx",
  },
  ul: {
    variant: "text.paragraph.default",
  },
  ol: {
    variant: "text.paragraph.default",
  },
  inlineCode: {
    backgroundColor: "accent",
    fontFamily: "monospace",
    fontSize: "96%",
    borderRadius: "s,",
    px: "2px",
    py: "0px",
    mx: "-1px",
  },
  table: {
    width: "100%",
    my: 4,
    borderCollapse: "separate",
    borderSpacing: 0,
    thead: { color: "white", bg: "primary" },
    "th,td": {
      textAlign: "left",
      px: [2, 3],
      py: "4px",
      pr: "4px",
    },
    th: {
      verticalAlign: "bottom",
    },
    td: {
      verticalAlign: "top",
      borderColor: "muted",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
    },
  },
  hr: {
    my: [4, 5],
    borderStyle: "bottom",
    borderBottom: "default",
    borderColor: "accent",
  },
};

export default styles;
