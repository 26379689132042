import textComponent from "../typography/components/text-component";
import headingComponent from "../typography/components/heading-component";

const text = {
  // ************
  // ROOT TEXT STYLES
  // applied to body element in styles.root
  // ************

  root: {
    fontFamily: "sans",
    fontSize: "16px",
    fontWeight: "regular",
    // these styles apply to html elements
    h1: { variant: "text.heading.h1" },
    h2: { variant: "text.heading.h2" },
    h3: { variant: "text.heading.h3" },
    h4: { variant: "text.heading.h4" },
    h5: { variant: "text.heading.h5" },
    h6: { variant: "text.heading.h6" },
    p: { variant: "text.paragraph.default" },
  },

  // ************
  // TEXT COMPONENT
  // ************
  ...textComponent,

  // ************
  // HEADING COMPONENT
  // ************
  heading: {
    ...headingComponent,
  },
};

export default text;
