/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { BsChevronDoubleDown } from "react-icons/bs";
import { AnimatedContent } from "@smerth/gatsby-theme-theme-ui";

const keyframes = {
  // generic answer to set everything back to original state
  clear: {
    "@keyframes clear": {
      "100%": {
        transform: "none",
      },
    },
  },
  // animate the pulsing of scroll icon
  pulse: {
    "@keyframes pulse": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(0.5)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
};

function AnimatedScrollPrompt({ className, size, color, background, delay }) {
  return (
    <AnimatedContent
      className={className}
      animation="scollPromptSlideUp"
      delay={delay}
      sx={{
        bg: "rgba(255, 255, 255, 0.1)" || background,
        background: background,
        position: "absolute",
        bottom: 0,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <AnimatedContent
        animation="scollPromptSlideDown"
        delay={`calc(${delay} + 1000ms)`}
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <BsChevronDoubleDown
          sx={{
            color: color,
            fontSize: "35px",
            position: "relative",
            animation: "pulse",
            animationDuration: "1800ms",
            animationDelay: "0ms",
            animationIterationCount: "infinite",
            animationDirection: "alternate", // foreward | reverse | alternate | alternate-reverse
            animationTimingFunction: "ease-in-out", // ease | linear | ease-in | ease-out | ease-in-out
            // https://stackoverflow.com/questions/42446163/elements-with-animation-delay-briefly-appear-before-fading-in-using-purely-css3
            // animationFillMode: "none",
            ...keyframes.pulse,
          }}
        />
      </AnimatedContent>
    </AnimatedContent>
  );
}

export default AnimatedScrollPrompt;
