/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui";
import { useEffect, useState } from "react";
import { AnimatedContent } from "@smerth/gatsby-theme-theme-ui";

// TODO: went to great lengths to calc height here using state and var maybe because of differences in how mobile uses vh,... compare behavior of parallax page and this component.  If you need these calc in anywhere else that 100vh is used you need to move this to app wrapper and call in state from hook.

function ParallaxHeaderWithSvg({ className, children, component, palette }) {
  function useDimensions() {
    let [height, setHeight] = useState();
    let [width, setWidth] = useState();

    useEffect(() => {
      // window and document are not available during gatsby build step
      setHeight(document.documentElement.clientHeight);
      // setHeight(window.innerHeight)

      // const getHeight = () => document.documentElement.clientHeight
      const getHeight = () => window.innerHeight;

      const resizeHeight = () => {
        setHeight(getHeight());
      };

      // set resize listener
      window.addEventListener("resize", resizeHeight);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeHeight);
      };
    }, []); // useEffect executes once

    useEffect(() => {
      // window and document are not available during gatsby build step
      setWidth(document.documentElement.clientWidth);
      // setWidth(window.innerHeight)

      // const getWidth = () => document.documentElement.clientWidth
      const getWidth = () => window.innerWidth;

      const resizeWidth = () => {
        setWidth(getWidth());
      };

      // set resize listener
      window.addEventListener("resize", resizeWidth);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeWidth);
      };
    }, []); // useEffect executes once

    return { height, width };
  }

  const { height, width } = useDimensions();

  useEffect(() => {
    // See: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    let vh = height * 0.01;

    // set the --vh custom property on the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [height]);

  // const color = palette?.text || "chrome.text";
  const colorMuted = palette?.textMuted || "chromeTextMuted";
  const backgroundColor = palette?.background || "chrome";
  const backgroundColorMuted = palette?.backgroundMuted || "chromeMuted";

  return (
    <Box
      className={className}
      sx={{
        color: colorMuted,
        bg: backgroundColor,
        position: "relative", // AnimatedScrollPrompt & set
        display: "grid", // for background/text layering
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        overflow: "hidden", // AnimatedScrollPrompt
        height: (theme) =>
          `calc((var(--vh, 1vh) * 100) - ${theme.sizes.header})`,
      }}
    >
      <AnimatedContent
        animation="slideDown"
        sx={{
          position: "fixed",
          width: "100%",
          display: "grid",
          justifyItems: "center",
          alignItems: "center",
          height: (theme) =>
            `calc((var(--vh, 1vh) * 100) - ${theme.sizes.header})`,
          svg: {
            height:
              height > width
                ? "auto"
                : (theme) =>
                    `calc((var(--vh, 1vh) * 100) - ${theme.sizes.header})`,
            transition: "height 600ms ease-in-out",
            width: width > height ? "auto" : `${width}px`,
            fill: backgroundColorMuted,
          },
        }}
      >
        {component}
      </AnimatedContent>
      <Container
        sx={{
          gridColumn: "1",
          gridRow: "1",
          position: "relative",
          display: "grid",
          justifyItems: "center",
          alignContent: "center",
          height: (theme) =>
            `calc((var(--vh, 1vh) * 100) - ${theme.sizes.header})`,
          transition: "height 800ms ease-in-out",
        }}
      >
        {children}
      </Container>
    </Box>
  );
}

export default ParallaxHeaderWithSvg;
