/** @jsx jsx */
import { jsx, Heading } from "theme-ui";
import { TitleWithClippedGradient } from "@smerth/gatsby-theme-theme-ui";

const SectionTitle = ({
  children,
  className,
  color,
  gradient,
  textAlign,
  size,
  as,
  noMargins,
}) => {
  let styles = {};

  switch (size) {
    case "sm":
      styles = {
        fontSize: 9,
      };
      break;
    case "md":
      styles = {
        fontSize: 12,
      };
      break;
    case "lg":
      styles = {
        fontSize: 15,
      };
      break;
    default:
      styles = {
        fontSize: 12,
      };
      break;
  }

  return (
    <Heading
      className={className}
      as={as}
      sx={{
        color: color ? color : "text",
        fontFamily: "display",
        fontWeight: "display",
        letterSpacing: "display",
        margin: noMargins ? "0" : "0 0 1.38rem",
        textAlign: textAlign,
        ...styles,
      }}
    >
      {gradient ? (
        <TitleWithClippedGradient gradient={gradient}>
          {children}
        </TitleWithClippedGradient>
      ) : (
        children
      )}
    </Heading>
  );
};

export default SectionTitle;
