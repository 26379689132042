/** @jsx jsx */
import { jsx, Container } from "theme-ui";

function WrapperWithSidebar({
  children,
  className,
  sidebar,
  fluid,
  right,
  bg,
  width,
  gap,
  noMargin,
  noMarginTop,
  noMarginBottom,
  noPadding,
  noPaddingTop,
  noPaddingBottom,
  noPaddingX,
  noAsidePadding,
  noAsideBorders,
  noMainPadding,
  noMainBorders,
  reverseOrderOnMobile,
}) {
  let asideWidth = [];
  let mainWidth = [];

  switch (width) {
    case "xs":
      asideWidth = ["100%", "100%", "150px"];
      mainWidth = ["100%", "100%", "1fr"];
      break;
    case "sm":
      asideWidth = ["100%", "100%", "250px"];
      mainWidth = ["100%", "100%", "1fr"];
      break;
    case "md":
      asideWidth = ["100%", "100%", "350px"];
      mainWidth = ["100%", "100%", "1fr"];
      break;
    // TODO: lg and xl must stack one size sooner
    case "lg":
      asideWidth = ["100%", "100%", "50%"];
      mainWidth = ["100%", "100%", "50%"];
      break;
    case "xl":
      asideWidth = ["100%", "100%", "55%"];
      mainWidth = ["100%", "100%", "45%"];
      break;
    case "auto":
      asideWidth = ["auto", "auto", "auto"];
      // mainWidth = ["auto", "auto", "auto"];
      break;
    default:
      asideWidth = ["100%", "100%", "38.2%"];
      mainWidth = ["100%", "100%", "61.8"];
      break;
  }

  return (
    <Container
      className={className}
      bg={bg}
      sx={{
        mt: noMarginTop || noMargin ? 0 : 6,
        mb: noMarginBottom || noMargin ? 0 : 6,
      }}
    >
      <Container
        variant={fluid ? "fluid" : "constrainedNoPadding"}
        sx={{
          display: reverseOrderOnMobile
            ? ["flex", "flex", "flex", "flex", "flex"]
            : ["block", "block", "flex", "flex", "flex"],
          flexDirection: right
            ? ["column-reverse", "column-reverse", "row"]
            : [
                "column-reverse",
                "column-reverse",
                "row-reverse",
                "row-reverse",
              ],
          px: noPaddingX || noPadding ? ["0px", "0px", "0px"] : [4, 4, 0],
          pt:
            noPaddingTop || noPadding
              ? "0px"
              : gap
              ? gap
              : ["gap.lg", "gap.lg", "gap.lg", "gap.lg", "gap.xl"],
          pb:
            noPaddingBottom || noPadding
              ? "0px"
              : gap
              ? gap
              : ["gap.lg", "gap.lg", "gap.lg", "gap.lg", "gap.xl"],
          gap: gap ? gap : ["gap.lg", "gap.lg", "gap.lg", "gap.lg", "gap.xl"],
        }}
      >
        <Container
          as="main"
          variant={
            noMainBorders ? "" : noMainPadding ? "borderNoPadding" : "border"
          }
          sx={{
            width: mainWidth,
          }}
        >
          {children}
        </Container>
        <Container
          as="aside"
          variant={
            noAsideBorders ? "" : noAsidePadding ? "borderNoPadding" : "border"
          }
          sx={{
            width: asideWidth,
            mt: [6, 6, 0],
          }}
        >
          {sidebar}
        </Container>
      </Container>
    </Container>
  );
}

export default WrapperWithSidebar;
