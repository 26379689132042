/** @jsx jsx */
import { jsx } from "theme-ui"
import {
  Accordion,
  AccordionItem,
  MenuVertical,
} from "@smerth/gatsby-theme-theme-ui"

// fetch data
import useGetStartedLinks from "../hooks/graphql/use-get-started-links"
import useComponentLinks from "../hooks/graphql/use-component-links"
import useHelperLinks from "../hooks/graphql/use-helper-links"
import useStylesLinks from "../hooks/graphql/use-styles-links"
import useScaleLinks from "../hooks/graphql/use-scale-links"
// import useFunctionLinks from "../hooks/graphql/use-function-links"
// import useExamplesLinks from "../hooks/graphql/use-examples-links"

// export const AccordionContext = React.createContext()

function SidebarDocumentation(props) {
  const {
    accordionVariant,
    menuVariant,
    className,
    direction,
    noHelpers,
    noBorder,
    height,
  } = props

  const start = useGetStartedLinks()
  const scales = useScaleLinks()
  const styles = useStylesLinks()
  const components = useComponentLinks()
  // const functions = useFunctionLinks()
  const helpers = useHelperLinks()
  // const examples = useExamplesLinks()

  const data = []
  data.push(start)
  data.push(scales)
  data.push(styles)
  data.push(components)
  // if (!noFunctions) {
  //   data.push(functions)
  // }
  if (!noHelpers) {
    data.push(helpers)
  }
  // data.push(examples)

  return (
    <Accordion className={className} direction={direction}>
      {data.map((item, index) => {
        const { items } = item
        return (
          <AccordionItem
            variant={accordionVariant}
            as="nav"
            key={index}
            data={item}
            direction={direction}
            height="lg"
          >
            <MenuVertical
              data={items}
              componentMap={{}}
              // indent={indent}
              // indent="false"
              noPadding
              height={height}
              noBorder={noBorder}
              variant={menuVariant || "ui"}
              sx={{
                a: {
                  "&.active": {
                    fontWeight: "display",
                    display: "list-item",
                    listStyleType: "circle",
                    listStylePosition: "inside",
                    paddingInlineStart: "0.1ch",
                  },
                },
              }}
            />
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default SidebarDocumentation
