/** @jsx jsx */
import { jsx, Badge, Box, Text } from "theme-ui";
import { Link } from "@smerth/gatsby-theme-theme-ui";

function TagRainbow({ data, className, noCount, size, gap }) {
  // Thanks to: https://natclark.com/tutorials/javascript-lighten-darken-hex-color/
  const newShade = (hexColor, magnitude) => {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  };

  return (
    <Box as="nav" className={className}>
      <Text
        as="ul"
        variant={`ui.${size}`}
        sx={{
          p: "0px",
          display: "flex",
          flexWrap: "wrap",
          gap: gap,
          listStyle: "none",
        }}
      >
        {data &&
          data.map((item) => (
            <Link
              key={item.id}
              href={item.url}
              variant="ui"
              sx={{
                color: item.color,
                bg: item.background,
                px: 1,
                borderRadius: "md",
                lineHeight: "normal",
                display: "flex",
                alignItems: "center",
                gap: 1,
                textDecoration: "none",
                "&:hover": {
                  color: item.color,
                  bg: newShade(`${item.background}`, -20),
                },
              }}
            >
              {item.label}
              {!noCount ? (
                <Badge
                  as="div"
                  sx={{
                    fontSize: "70%",
                    fontWeight: "normal",
                    color: item.color,
                    bg: newShade(`${item.background}`, 90),
                  }}
                >
                  {item.count}
                </Badge>
              ) : (
                ""
              )}
            </Link>
          ))}
      </Text>
    </Box>
  );
}

export default TagRainbow;
