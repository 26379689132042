/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { useState } from "react";
import { Button } from "@smerth/gatsby-theme-theme-ui";

function ButtonGroup({
  data,
  shadow,
  right,
  size,
  initialState,
  handleClick,
  ...rest
}) {
  const ini = data.filter((d) => d.id === initialState);
  const [active, setActive] = useState(initialState ? ini[0] : data[0]);

  function handleClickEvent(b) {
    setActive(b);
    handleClick(b);
  }

  return (
    <Flex sx={{ gap: 2 }}>
      {data.map((b) => {
        return (
          <Button
            key={b.id}
            label={b.label}
            variant={b.variant || "primary"}
            onClick={() => handleClickEvent(b)}
            disabled={active !== b ? false : true}
            // mr={[2, 3]}
            // {...rest}
            icon={b.icon}
            shadow={b.shadow || shadow}
            size={b.size || size}
            right={b.right || right}
            sx={{ color: active !== b ? "white" : "white" }}
          />
        );
      })}
    </Flex>
  );
}

export default ButtonGroup;
