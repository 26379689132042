/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui";
import { Link } from "@smerth/gatsby-theme-theme-ui";

function Credits({
  className,
  developerName,
  developerGithubAccount,
  developerNpmURL,
}) {
  return (
    <Box className={className}>
      <Text as="span" variant="ui.4">
        {`Coded by ${developerName}. Find me on `}
        <Link
          variant="chrome"
          href={developerGithubAccount}
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </Link>
        {" or "}
        <Link
          variant="chrome"
          href={developerNpmURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          NPM
        </Link>
        .
      </Text>

      <Text as="p" variant="ui.5">
        {"This site uses "}
        <Link
          variant="chrome"
          href="https://www.gatsbyjs.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          GatsbyJS
        </Link>
        {" and "}
        <Link
          variant="chrome"
          href="https://theme-ui.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Theme-UI
        </Link>
        {" on the frontend, "}
        <Link
          variant="chrome"
          href="https://aws-amplify.github.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AWS Amplify
        </Link>
        {" on the backend, and "}
        <Link
          variant="chrome"
          href="https://graphql.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          GraphQL
        </Link>
        {" in the middle!"}
      </Text>
    </Box>
  );
}

export default Credits;
