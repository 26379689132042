const sharedStyles = {
  fontFamily: "sans",
  borderRadius: "circle",
};

const badges = {
  ui: {
    ...sharedStyles,
    color: "uiTextHover",
    bg: "muted",
  },
  primary: {
    ...sharedStyles,
    color: "primaryTextHover",
    bg: "primaryMuted",
  },
  secondary: {
    ...sharedStyles,
    color: "secondaryTextHover",
    bg: "secondaryMuted",
  },
  chrome: {
    ...sharedStyles,
    color: "chromeTextHover",
    bg: "chromeMuted",
  },
  black: {
    ...sharedStyles,
    color: "blackTextHover",
    bg: "blackMuted",
  },
  grey: {
    ...sharedStyles,
    color: "greyTextHover",
    bg: "greyMuted",
  },
  white: {
    ...sharedStyles,
    color: "whiteTextHover",
    bg: "whiteMuted",
  },
  info: {
    ...sharedStyles,
    color: "infoTextHover",
    bg: "infoMuted",
  },
  success: {
    ...sharedStyles,
    color: "successTextHover",
    bg: "successMuted",
  },
  warning: {
    ...sharedStyles,
    color: "warningTextHover",
    bg: "warningMuted",
  },
  error: {
    ...sharedStyles,
    color: "errorTextHover",
    bg: "errorMuted",
  },
};

export default badges;
