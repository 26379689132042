// margin, margin-top, margin-right, margin-bottom, margin-left, padding, padding-top, padding-right, padding-bottom, padding-left, grid-gap, grid-column-gap, grid-row-gap

// https://type-scale.com/
// Augmented Fourth
// Base size 16px

// REM
const space = {
  0: "0rem",
  1: "0.25rem",
  2: "0.354rem",
  3: "0.5rem",
  4: "0.707rem",
  5: "1rem",
  6: "1.414rem",
  7: "1.999rem",
  8: "2.827rem",
  9: "3.998rem",
  10: "5.653rem",
  11: "7.993rem",
  12: "11.302rem",
  13: "15.981rem",
  14: "22.597rem",
  15: "31.952rem",
  16: "45.18rem",
  xs: "0.707rem",
  sm: "1rem",
  md: "1.414rem",
  lg: "1.999rem",
  xl: "2.827rem",
  link: { xs: "6px", sm: "10px", md: "14px", lg: "22px" },
  gap: { xs: "6px", sm: "10px", md: "14px", lg: "22px", xl: "36px" },
};

export default space;
