/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { Link } from "gatsby";
import { BiChevronRight } from "react-icons/bi";

function Breadcrumbs({ data, inactiveLinkColor, className }) {
  const { item, breadcrumbs } = data;
  return (
    <Text
      as="div"
      variant="ui.4"
      className={className}
      sx={{
        my: 4,
        display: "inline-flex",
        flexWrap: "wrap",
        color: "inherit",
        a: {
          color: "inherit",
          textDecoration: "none",
          "&:visited": { color: "inherit" },
        },
      }}
    >
      {breadcrumbs.map((crumb) => {
        return (
          <span key={crumb.slug} sx={{ display: "flex", alignItems: "center" }}>
            <Link to={crumb.url}>{crumb.slug}</Link>
            <BiChevronRight />
          </span>
        );
      })}
      <span sx={{ color: inactiveLinkColor ? inactiveLinkColor : "gray" }}>
        {item.slug}
      </span>
    </Text>
  );
}

export default Breadcrumbs;
