/** @jsx jsx */
import { jsx, Text } from "theme-ui";

function SiteSlogan({ className, slogan, variant, hide }) {
  const hideOffscreen = {
    position: "absolute",
    left: "-10000px",
    top: "auto",
    width: "1px",
    height: "1px",
    overflow: "hidden",
  };

  return (
    <Text
      as="p"
      variant={variant}
      className={className}
      sx={hide ? { ...hideOffscreen } : ""}
    >
      {slogan}
    </Text>
  );
}

export default SiteSlogan;
