/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui"
import { MenuIcons, Copyright } from "@smerth/gatsby-theme-theme-ui"
import {
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaGithub,
  FaHome,
} from "react-icons/fa"
import { IoLogoNpm } from "react-icons/io"

import { IoLogoYoutube } from "react-icons/io"
import { GrInstagram } from "react-icons/gr"
import useSocialMediaMenuData from "../../../hooks/graphql/use-social-media-menu-data.js"

export default function FooterContent({ className }) {
  const socialMediaMenuData = useSocialMediaMenuData()

  return (
    <Box className={className} py={8}>
      <Box
        as="nav"
        role="navigation"
        variant="text.ui.7"
        sx={{
          color: "chromeText",
          px: 3,
          py: 3,
        }}
      >
        <Text as="p" variant="ui.8" sx={{ mb: 3, textAlign: "center" }} pb={4}>
          Connect with me!
        </Text>
        <MenuIcons
          justify={["", "", "center"]}
          data={socialMediaMenuData}
          variant="chrome"
          size={["inherit", "inherit", "36px"]}
          gap
          showLabelsOnMobile
          stackOnMobile
          iconMap={{
            homepage: FaHome,
            facebook: FaFacebook,
            twitter: FaTwitter,
            linkedIn: FaLinkedinIn,
            youtube: IoLogoYoutube,
            instagram: GrInstagram,
            github: FaGithub,
            npm: IoLogoNpm,
          }}
        />
      </Box>
      <Box color="chromeText" mt={6}>
        <Copyright title="smerth.com" align="right" sx={{ my: 4 }} />
      </Box>
    </Box>
  )
}
