/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui";
import { useContext, useEffect } from "react";
import Headroom from "react-headroom";
import {
  OverlayContent,
  OverlayHeaderDesktop,
  OverlayHeaderMobile,
} from "@smerth/gatsby-theme-theme-ui";
import { OverlayContext } from "./SiteWrapper";

function Overlay({ className, palette, fluid }) {
  const { isOverlayOpen, isSmallScreen } = useContext(OverlayContext);

  // console.log("isSmallScreen: ", isSmallScreen);

  // stop body scrolling under the overlay when the overlay is open
  useEffect(() => {
    const overflow = isOverlayOpen ? "hidden" : "visible";
    document.getElementsByTagName("body")[0].style.overflow = overflow;
  }, [isOverlayOpen]);

  // Use palette colors if they are passed in
  const color = palette?.text || "chromeText";
  const colorMuted = palette?.textMuted || "chromeText";
  const backgroundColor = palette?.background || "chrome";

  const ConditionalHeader = ({ isSmallScreen }) => {
    if (isSmallScreen === null) {
      return null;
    } else if (isSmallScreen === true) {
      return <OverlayHeaderMobile />;
    } else {
      return <OverlayHeaderDesktop />;
    }
  };

  // function to conditionally render the right menu
  // const renderOverlayHeader = (isSmallScreen) => {
  //   if (isSmallScreen === null) {
  //     return null;
  //   } else if (isSmallScreen === true) {
  //     return <OverlayHeaderMobile />;
  //   } else {
  //     return <OverlayHeaderDesktop />;
  //   }
  // };

  // isSmallScreen is not available on the server during SSR
  // call the render function after the window becomes available
  // useEffect(() => {
  //   const window = typeof window !== "undefined";
  //   if (window) {
  //     renderOverlayHeader(isSmallScreen);
  //   }
  // }, [isSmallScreen]); // Only re-run the effect if isSmallScreen changes

  return (
    <Box
      className={className}
      sx={{
        position: "relative", // stay in place
        zIndex: 300, // sit on top
        fontSize: [5, 5, 4],
      }}
    >
      {/* Overlay content */}
      {isSmallScreen === true && (
        <div
          sx={{
            boxShadow: isOverlayOpen ? "2px 2px 2px rgba(0,0,0,0.25)" : "none",
            color: "text",
            backgroundColor: "background",
            position: "fixed", // stay in place
            left: 0,
            top: 0,
            width: "100%",
            height: isOverlayOpen ? "100%" : "0%",
            overflowY: "visible",
            overflowX: "hidden", // Disable horizontal scroll in overlay
            transition: "0.5s",
          }}
        >
          <OverlayContent />
        </div>
      )}

      <Headroom
        sx={{
          ".headroom": {
            height: "header",
            color: colorMuted,
            bg: backgroundColor,
            boxShadow: isOverlayOpen ? "2px 2px 2px rgba(0,0,0,0.25)" : "none",
            top: 0,
            left: 0,
            right: 0,
            "& > div": {
              mx: "auto",
            },
          },
          ".headroom--unfixed": {
            position: "relative",
            transform: "translateY(0)",
            // unfixed means "in the flow", the header before scrolling
            bg: backgroundColor,
          },
          ".headroom--scrolled": {
            transition: "transform 200ms ease-in-out",
            "& > div": {
              height: "auto",
            },
          },
          ".headroom--unpinned": {
            position: "fixed",
            transform: "translateY(-100%)",
          },
          ".headroom--pinned": {
            position: "fixed",
            transform: "translateY(0%)",
            boxShadow: "2px 2px 2px rgba(0,0,0,0.25)",
          },
        }}
      >
        <Container
          variant={fluid ? "fluid" : "constrained"}
          sx={{ position: "sticky" }}
        >
          {/* {renderOverlayHeader()} */}
          <ConditionalHeader isSmallScreen={isSmallScreen} />
        </Container>
      </Headroom>
    </Box>
  );
}

export default Overlay;
