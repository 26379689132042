const sharedLinkStyles = {
  variant: "text.paragraph.default",
  padding: 0,
  margin: 0,
  textDecoration: "none",
};

const sharedUIStyles = {
  margin: 0,
  cursor: "pointer",
  fontFamily: "sans",
  fontWeight: "light",
  fontSize: "inherit",
  textDecoration: "none",
  transition: "ease color 250ms",
};

const sharedHoverStyles = {
  textDecoration: "none",
};

const links = {
  mdx: {
    variant: "text.paragraph.default",
    padding: 0,
    margin: 0,
    color: "primary",
    textDecoration: "underline",
    transition: "ease color 250ms",
    "&:hover": {
      color: "primaryMuted",
    },
  },
  ui: {
    ...sharedUIStyles,
    color: "uiText",
    "&:hover": {
      color: "uiTextHover",
      ...sharedHoverStyles,
    },
  },
  chrome: {
    ...sharedUIStyles,
    padding: 0,
    margin: 0,
    textDecoration: "none",
    color: "chromeText",
    "&:hover": {
      color: "chromeTextHover",
    },
  },
  primary: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "primaryText",
    "&:hover": {
      color: "primaryTextHover",
      ...sharedHoverStyles,
    },
  },
  secondary: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "secondaryText",
    "&:hover": {
      color: "secondaryTextHover",
      ...sharedHoverStyles,
    },
  },
  black: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "blackText",
    "&:hover": {
      color: "blackTextHover",
      ...sharedHoverStyles,
    },
  },
  grey: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "greyText",
    "&:hover": {
      color: "greyTextHover",
      ...sharedHoverStyles,
    },
  },
  white: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "whiteText",
    "&:hover": {
      color: "whiteTextHover",
      ...sharedHoverStyles,
    },
  },
  info: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "infoText",
    "&:hover": {
      color: "infoTextHover",
      ...sharedHoverStyles,
    },
  },
  success: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "successText",
    "&:hover": {
      color: "successTextHover",
      ...sharedHoverStyles,
    },
  },
  warning: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "warningText",
    "&:hover": {
      color: "warningTextHover",
      ...sharedHoverStyles,
    },
  },
  error: {
    ...sharedLinkStyles,
    ...sharedUIStyles,
    color: "errorText",
    "&:hover": {
      color: "errorTextHover",
      ...sharedHoverStyles,
    },
  },
  fancy: {
    color: "white",
    backgroundColor: "chrome",
    backgroundImage: (t) => t.gradients.action,
    border: "1px solid",
    borderColor: "chrome",
    borderRadius: "md",
    px: 2,
    py: 1,
    fontFamily: "sans",
    fontWeight: "light",
    fontSize: "inherit",
    lineHeight: "normal",
    textDecoration: "none",
    display: "flex",
    gap: 1,
    alignItems: "center",
    // transition: "ease all 250ms",
    // "&>span": { mx: 1 },
    "&:hover": {
      color: "chrome",
      backgroundColor: "#ffffff",
      backgroundImage: "none",
      border: "1px solid",
      borderColor: "chrome",
    },
  },
};

export default links;
