/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { FlexMenuLink } from "@smerth/gatsby-theme-theme-ui";

function VerticalMenuWithIcons({
  variant,
  className,
  size,
  data,
  iconMap,
  indentLinks,
  height,
  noBorder,
}) {
  function Icon({ icon }) {
    const IconComponent = iconMap[icon];
    return <IconComponent icon={icon} />;
  }

  return (
    <Box
      className={className}
      as="ul"
      sx={{
        padding: 0,
        margin: 0,
        listStyleType: "none",
      }}
    >
      {data.map((item) => {
        return (
          <li
            key={item.id}
            sx={{
              borderWidth: noBorder ? "" : "thin",
              borderColor: noBorder ? "" : `${variant}Muted`,
              borderStyle: noBorder ? "none" : "bottom",
              ":last-child": {
                borderStyle: "none",
              },
            }}
          >
            <FlexMenuLink
              variant={variant}
              item={item}
              noExternalLinkIcon
              sx={{ gap: 3, pl: indentLinks ? 3 : "0", py: `link.${height}` }}
            >
              <Box as="span" variant="layout.svg" sx={{ fontSize: size }}>
                <Icon icon={item.icon} />
              </Box>
              <Box as="span">{item.label}</Box>
            </FlexMenuLink>
          </li>
        );
      })}
    </Box>
  );
}

export default VerticalMenuWithIcons;
