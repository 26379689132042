/** @jsx jsx */
import { jsx, Container, Box } from "theme-ui";
import {
  FooterContent,
  FooterWrapper,
  Overlay,
} from "@smerth/gatsby-theme-theme-ui";

// TODO: should the layout take a constrained prop
function LayoutNoSidebar({ className, children, fluid }) {
  return (
    <Container className={className}>
      <Box as="header" sx={{ width: "100%" }}>
        <Overlay fluid={fluid} />
      </Box>
      <Container sx={{ flex: 1 }}>{children}</Container>
      <FooterWrapper>
        <Container variant={fluid ? "fluid" : "constrained"}>
          <FooterContent />
        </Container>
      </FooterWrapper>
    </Container>
  );
}

export default LayoutNoSidebar;
