/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import React from "react";
import { FlexMenuLink, menuFlexFunctions } from "@smerth/gatsby-theme-theme-ui";

function FlexMenu(props) {
  const {
    variant,
    className,
    componentMap,
    data,
    expand,
    gap,
    height,
    justify,
    wrapOnMobile,
    ...rest
  } = props;

  const {
    calculateFlexProperty,
    calculateListItemPaddingLeft,
    calculateListItemPaddingRight,
    // calculateLinkPadding,
  } = menuFlexFunctions;

  function ComponentFromMap(props) {
    const newProps = {
      height: height,
      ...props,
    };
    const { item } = props;

    if (typeof componentMap[item.component] !== "undefined") {
      const Component = componentMap[item.component];
      return <Component {...newProps} />;
    } else {
      return React.createElement(FlexMenuLink, newProps);
    }
  }

  return (
    <Box
      as="nav"
      className={className}
      sx={{
        color: "inherit",
        backgroundColor: "inherit",
        display: "flex",
        flex: "1 0 auto",
        alignSelf: ["normal", "center"],
        overflow: wrapOnMobile
          ? ["auto", "auto", "hidden", "hidden", "hidden"]
          : "auto",
        // hide overflow scrollbar
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      role="navigation"
      {...rest}
    >
      <ul
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          py: wrapOnMobile ? [gap, gap, 0] : 0,
          margin: 0,
          display: wrapOnMobile
            ? ["inline-flex", "inline-flex", "flex"]
            : "flex",
          flex: wrapOnMobile ? [1, 1, "1 0 auto"] : "1 0 auto",
          flexWrap: wrapOnMobile
            ? ["wrap", "wrap", "nowrap", "nowrap", "nowrap"]
            : "nowrap",
          overflow: wrapOnMobile
            ? ["auto", "auto", "scroll", "scroll", "scroll"]
            : "auto",
          // hide overflow scrollbar
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          // gap: wrapOnMobile ? gap : "",
          gap: gap,
          alignContent: "center",
          listStyleType: "none",
          // justifyContent: props.justify,
          justifyContent: wrapOnMobile
            ? ["start", "start", props.justify]
            : props.justify,
        }}
      >
        {data.map((item) => {
          const props = {
            variant: variant,
            item,
            justify,
            expand,
            // calculateLinkPadding,
            className,
          };
          return (
            <li
              key={item.id}
              className={className}
              sx={{
                display: "flex",
                flex:
                  (props.expand && justify === "space-between") ||
                  (props.expand && justify === "space-around")
                    ? "1 0 auto"
                    : calculateFlexProperty(justify),
                flexWrap: "no-wrap",
                // alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                listStyleType: "none",
                paddingLeft: calculateListItemPaddingLeft(justify, expand),
                paddingRight: calculateListItemPaddingRight(justify, expand),
              }}
            >
              <ComponentFromMap {...props} />
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

export default FlexMenu;
