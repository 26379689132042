/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui";
import rangeParser from "parse-numeric-range";
import Highlight from "prism-react-renderer";

// Calculate lines in the code-block to highlight
const calculateLinesToHighlight = (meta) => {
  const RE = /{([\d,-]+)}/;
  if (RE.test(meta)) {
    const strlineNumbers = RE.exec(meta)[1];
    const lineNumbers = rangeParser(strlineNumbers);
    return (i) => lineNumbers.includes(i + 1);
  } else {
    return () => false;
  }
};

// TODO: rename HighlightedCodeblock to DisplayHighlightedCode
function HighlightedCodeblock({
  codeString,
  defaultProps,
  language,
  metastring,
  theme,
}) {
  const shouldHighlightLine = calculateLinesToHighlight(metastring);

  return (
    <Box
      sx={{
        position: "relative",
        pt: "28px",
        pb: [3, 4],
        bg: "codeblock",
      }}
    >
      {language && <Container variant="language">{language}</Container>}
      <Highlight
        {...defaultProps}
        code={codeString}
        language={language}
        theme={theme}
      >
        {({ className, tokens, getLineProps, getTokenProps }) => {
          return (
            <Box as="pre" className={className}>
              {tokens.map((line, i) => {
                const lineProps = getLineProps({ line, key: i });
                const highlightLine = shouldHighlightLine(i);
                return (
                  <div
                    {...lineProps}
                    sx={{
                      px: [2, 3],
                      mr: "-5px",
                      // add styles for highlighted lines
                      backgroundColor: highlightLine
                        ? "codeBlockHighlight"
                        : "inherit",
                      borderLeft: highlightLine
                        ? (theme) => `5px solid ${theme.colors.secondary}`
                        : "inherit",
                    }}
                  >
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                );
              })}
            </Box>
          );
        }}
      </Highlight>
    </Box>
  );
}

export default HighlightedCodeblock;
