import { ButtonGroup } from "@smerth/gatsby-theme-theme-ui";
import { BiBus, BiCar } from "react-icons/bi";
import { BsPatchCheckFill } from "react-icons/bs";
import { FaPlane } from "react-icons/fa";
import buttonGroupData from "../../../../documentation/data/buttonGroup.yaml";
import * as React from 'react';
export default {
  ButtonGroup,
  BiBus,
  BiCar,
  BsPatchCheckFill,
  FaPlane,
  buttonGroupData,
  React
};