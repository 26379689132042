/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui";
import { useContext, useState, useEffect } from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import { Button, OverlayContext } from "@smerth/gatsby-theme-theme-ui";
import { BiChevronRight } from "react-icons/bi";

function AccordionItem({
  as,
  className,
  children,
  data,
  direction,
  height,
  variant,
}) {
  const { id, label, description, items } = data;
  const { isSmallScreen } = useContext(OverlayContext);

  // get the url of the current page
  const location = useLocation();

  // pageMatch is true if the items to be rendered contain a url matching the current page
  const pageMatch = items?.find((el) => el.url === location?.pathname);
  // if pageMatch is true set initialState to id
  let initialState = pageMatch ? id : null;
  // state used to keep accordion item open
  const [isSelected, setIsSelected] = useState(initialState);

  // state used to display cheveron icons depending on screensize and direction
  const [, setIsDisabled] = useState(null);

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      if (direction === "row" && !isSmallScreen) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [direction, isSmallScreen]); // Only re-run the effect if isSmallScreen changes

  const handleClick = (i) => {
    if (isSelected === i) {
      return setIsSelected(null);
    }
    setIsSelected(i);
  };

  return (
    <Box
      as="div"
      className={className}
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1, // equal width items
      }}
    >
      <Button
        variant={variant}
        label={label}
        icon={<div />} // TODO: without div layout breaks! re-work this!
        // disabled={isDisabled}
        right
        sx={{
          fontFamily: "sans",
          fontWeight: "light",
          fontSize: 5,
          pl: "0px",
          py: height ? `link.${height}` : 6,
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "left",
          border: "0",
          borderRadius: "0",
          outline: "0",
          boxShadow: "0",
          svg: { m: "0", p: "0" },
        }}
        onClick={() => handleClick(id)}
      >
        <BiChevronRight
          sx={{
            transform: isSelected ? "rotate(90deg)" : "",
            transition: "all 500ms cubic-bezier(0,1,0,1)",
            display:
              direction === "row" && !isSmallScreen ? "none" : "inline-flex",
          }}
        />
      </Button>
      <Box
        as={as}
        className={isSelected ? "open" : "closed"}
        sx={{
          pl: isSmallScreen ? 4 : 0,
          ml: direction === "row" && !isSmallScreen ? "0px" : [2, 3],
          overflow: "hidden",
          maxHeight: "0px",
          transition: "max-height 500ms cubic-bezier(0,1,0,1)",
          "&.open": {
            maxHeight: "9999px",
          },
          "&.closed": {
            maxHeight:
              direction === "row" ? ["0px", "", "9999px", "", ""] : "0",
          }, // stay open on desktop when in horizontal mode
        }}
      >
        {description && (
          <Text
            as="p"
            variant="ui.3"
            sx={{
              fontStyle: "italic",
              fontWeight: "extraLight",
              my: 4,
            }}
          >
            {description}
          </Text>
        )}
        {children}
      </Box>
    </Box>
  );
}

export default AccordionItem;
