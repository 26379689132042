const letterSpacings = {
  display: "-1px",
  ui: "0px",
  heading: "0px",
  body: "normal",
  caps: "normal",
  label: "normal",
  branding: "normal",
};

export default letterSpacings;
