/** @jsx jsx */

import { jsx, Box, Divider, Text } from "theme-ui";
import * as themeComponents from "@smerth/gatsby-theme-theme-ui";

const { Link, PageTitle, Pre, ...rest } = themeComponents;

// The tags h1 - h6 come from theme.styles object
// this functions wraps each tag (h1 - h6) in a link
const heading = (Tag) => (props) => {
  const { className } = props;
  if (!props.id) return <Tag {...props} />;
  return (
    <Tag className={className} {...props}>
      <a href={`#${props.id}`}>{props.children}</a>
    </Tag>
  );
};

const Mark = ({ children, rest }) => {
  return (
    <Text
      as="mark"
      sx={{
        color: "text",
        bg: "background",
        backgroundImage: (t) => t.gradients.highlight,
        borderRadius: "md",
        px: 1,
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};

const Ul = (props) => {
  return (
    <ul sx={{}} {...props}>
      {props.children}
    </ul>
  );
};

const Li = (props) => {
  const { children } = props;

  // head :: [a] -> a
  const head = ([x, ...xs]) => x;
  // tail :: [a] -> [a]
  const tail = ([x, ...xs]) => xs;

  const regex = RegExp("task-list-item");

  if (regex.test(props.className)) {
    return (
      <li
        sx={{
          "&.task-list-item": {
            margin: "1em 0",
            display: "grid",
            gridTemplateColumns: "40px auto",
            alignItems: "center",
          },
        }}
        {...props}
      >
        {head(children)}
        <span>{tail(children)}</span>
      </li>
    );
  } else {
    return <li>{children}</li>;
  }
};

const Strong = ({ children }) => (
  <Box as="span" sx={{ fontWeight: "bold" }}>
    {children}
  </Box>
);

const components = {
  // html elements
  a: (props) => <Link variant="mdx" {...props} />,
  // https://forum.freecodecamp.org/t/newline-in-react-string-solved/68484
  // blockquote: ({ children, className }) => {
  //   const text = children.props.children;
  //   return (
  //     <Box
  //       className={className}
  //       as="div"
  //       sx={{
  //         my: [10, 11],
  //         ml: [4, 5, 6],
  //         borderStyle: "left",
  //         borderWidth: "thick",
  //         borderColor: "primary",
  //       }}
  //     >
  //       {text.split("\n").map((str) => (
  //         <Text as="p" key={str} sx={{ my: "0px" }}>
  //           {str}
  //         </Text>
  //       ))}
  //     </Box>
  //   );
  // },
  h1: ({ children, className }) => {
    return <PageTitle className={className}>{children}</PageTitle>;
  },
  h2: heading("h2"),
  h3: heading("h3"),
  h4: heading("h4"),
  h5: heading("h5"),
  h6: heading("h6"),
  hr: Divider,
  li: Li,
  mark: Mark,
  pre: Pre,
  strong: Strong,
  ul: Ul,
  ...rest,
};
export default components;
