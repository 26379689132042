import { Accordion, AccordionItem } from "@smerth/gatsby-theme-theme-ui";
import { Text } from "theme-ui";
import data from "../../../../documentation/data/accordionItem.yaml";
import * as React from 'react';
export default {
  Accordion,
  AccordionItem,
  Text,
  data,
  React
};