// https://type-scale.com/
// 1.067 - Minor Second

const rem = [
  "0.772rem",
  "0.823rem",
  "0.878rem",
  "0.937rem",
  "1rem",
  "1.067rem",
  "1.138rem",
  "1.215rem",
  "1.296rem",
  "1.383rem",
  "1.476rem",
  "1.575rem",
  "1.68rem",
  "1.793rem",
  "1.913rem",
  "2.041rem",
];

const px = [
  "12.34px",
  "13.17px",
  "14.05px",
  "15.00px",
  "16.00px",
  "17.07px",
  "18.22px",
  "19.44px",
  "20.74px",
  "22.13px",
  "23.61px",
  "25.19px",
  "26.88px",
  "28.68px",
  "30.60px",
  "32.65px",
];

const minorSecond = {
  rem,
  px,
};

export default minorSecond;
