const radii = {
  full: "9999px",
  circle: "50%",
  xs: "1px",
  sm: "2px",
  md: "4px",
  lg: "8px",
  xl: "16px",
};

export default radii;
