/** @jsx jsx */
import { jsx, Heading } from "theme-ui";

function PageTitle({ children, className, color, noMargins }) {
  return (
    <Heading
      className={className}
      as="h1"
      sx={{
        color: color ? `${color}Text` : "text",
        fontFamily: "sans",
        fontWeight: "display",
        letterSpacing: "display",
        margin: noMargins ? "0" : "0 0 1.38rem",
        fontSize: [9, 12, 11, 14, 15],
        a: {
          color: "inherit",
          textDecoration: "none",
          svg: {
            mr: [2, 3, 4],
          },
        },
      }}
    >
      {children}
    </Heading>
  );
}

export default PageTitle;
