/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import {
  SiteBranding,
  FlexMenu,
  SiteLogo,
  OverlayToggle,
} from "@smerth/gatsby-theme-theme-ui"
import useMobileMenuData from "../../../hooks/graphql/use-mobile-menu-data"
import useSiteMetadata from "../../../hooks/graphql/use-site-metadata"

export default function OverlayHeaderMobile(props) {
  const { className } = props
  const data = useMobileMenuData()
  const siteMetadata = useSiteMetadata()

  const componentMap = {
    SiteName: (props) => {
      return (
        <SiteBranding
          siteName={siteMetadata.title}
          siteSlogan={siteMetadata.slogan}
          size="sm"
          // hideSlogan
          {...props}
        >
          <SiteLogo size="48px" />
        </SiteBranding>
      )
    },
    OverlayToggle: (props) => {
      return <OverlayToggle size="48px" variant="chrome" {...props} />
    },
  }

  return (
    <Box sx={{ display: "flex", fontSize: "ui.8" }}>
      <FlexMenu
        className={className}
        variant="chrome"
        justify="space-between"
        height="header"
        data={data}
        componentMap={componentMap}
        sx={{
          // transition: "ease color 2500ms",
          height: "header",
        }}
      />
    </Box>
  )
}
