/** @jsx jsx */
import { jsx, Container } from "theme-ui";

function FooterWrapper({ children, palette }) {
  const color = palette?.text || "chromeText";
  const colorMuted = palette?.textMuted || "chromeTextHover";
  const backgroundColor = palette?.background || "chrome";

  return (
    <Container
      as="footer"
      sx={{
        color: colorMuted,
        background: backgroundColor,
      }}
    >
      {children}
    </Container>
  );
}

export default FooterWrapper;
