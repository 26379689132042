// https://type-scale.com/
// 1.125 - Major Second

const rem = [
  "0.624rem",
  "0.702rem",
  "0.79rem",
  "0.889rem",
  "1rem",
  "1.125rem",
  "1.266rem",
  "1.424rem",
  "1.602rem",
  "1.802rem",
  "2.027rem",
  "2.281rem",
  "2.566rem",
  "2.887rem",
  "3.247rem",
  "3.653rem",
];

const px = [
  "9.99px",
  "11.24px",
  "12.64px",
  "14.22px",
  "18.00px",
  "20.25px",
  "22.78px",
  "25.63px",
  "28.83px",
  "16.00px",
  "32.44px",
  "36.49px",
  "41.05px",
  "46.18px",
  "51.96px",
  "58.45px",
];

const majorSecond = {
  rem,
  px,
};

export default majorSecond;
