/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui"
import {
  // Link,
  MenuVertical,
  ColorModeToggle,
} from "@smerth/gatsby-theme-theme-ui"
import useDesktopMenuData from "../../../hooks/graphql/use-desktop-menu-data"
import SidebarDocumentation from "../../../components/SidebarDocumentation"
// import { GrGithub } from "react-icons/gr"

const componentMap = {
  // GithubLink: GithubLink,
  // GithubLink: () => (
  //   <Link variant="fancy" href="https://www.github.com" noExternalLinkIcon>
  //     <Box as="span" variant="layout.svg">
  //       <GrGithub />
  //     </Box>
  //     Get the code!
  //   </Link>
  // ),
  // ButtonCycleColorMode: (props) => {
  //   return (
  //     <ButtonCycleColorMode
  //       modes={["light", "dark", "purple", "ft", "monocle"]}
  //       {...props}
  //     />
  //   )
  // },
  ThemeToggle: ColorModeToggle,
}

function OverlayContent({ className }) {
  const data = useDesktopMenuData()

  let mainMenuData = [...data]
  // remove first link to "/" since it appears in the header
  mainMenuData.shift()
  // remove second link to "Documentation" since it appears in the header
  mainMenuData.shift()
  // remove the Github link
  const mainMenu = mainMenuData.filter((item) => item.label !== "Github")

  return (
    <Box className={className}>
      {/* This box offsets the content by the height of header */}
      <Box sx={{ height: "header" }} />
      {/* documentation menu / index */}
      <Container variant="constrained">
        <SidebarDocumentation
          height="lg"
          accordionVariant="accent"
          menuVariant="ui"
          // indent
          sx={{
            button: {
              borderStyle: "top",
              borderWidth: "thin",
              borderColor: "muted",
              // borderColor: "yellow",
            },
          }}
        />

        {/* other navigation links */}
        <MenuVertical
          variant="ui"
          height="md"
          expand="true"
          noBorder
          data={mainMenu}
          componentMap={componentMap}
          sx={{
            variant: "text.ui.8",
            "&.active": {
              textDecoration: "none",
            },
          }}
        />
      </Container>
    </Box>
  )
}

export default OverlayContent
