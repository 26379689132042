/** @jsx jsx */
import { jsx, Box, Card, Text } from "theme-ui";

// TODO: Rename to WrapperDocumentationExample
function CodeBlockWrapper({ children, className, title, align, type }) {
  let variant = "";

  switch (type) {
    case "live":
      variant = "documentation.liveCodeBlock";
      break;
    case "display":
      variant = "documentation.displayCode";
      break;
    case "array":
      variant = "documentation.componentArray";
      break;
    case "matrix":
      variant = "documentation.matrix";
      break;
    case "variants":
      variant = "documentation.componentArray";
      break;
    default:
      variant = "documentation.displayCode";
      break;
  }

  return (
    <Card
      className={className}
      variant={variant}
      sx={{
        mb: "gap.xl",
        alignItems: align || "center",
      }}
    >
      {title === null ? (
        ""
      ) : (
        <Text as="h1" variant="ui.4">
          {title}
        </Text>
      )}
      <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
    </Card>
  );
}

export default CodeBlockWrapper;
