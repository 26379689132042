/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { keyframes } from "@emotion/react";

function AnimatedContent({
  animation,
  className,
  children,
  delay,
  duration,
  count,
  direction,
  timing,
}) {
  const animations = {
    fadeIn: keyframes({ from: { opacity: 0 }, to: { opacity: 1 } }),
    slideFromLeft: keyframes({
      from: { transform: "translateX(-1600px)" },
      to: { transform: "translateX(0px)" },
    }),
    slideFromRight: keyframes({
      from: { transform: "translateX(1600px)" },
      to: { transform: "translateX(0px)" },
    }),
    slideFromBottom: keyframes({
      from: { transform: "translateY(300px) scale(0)" },
      to: { transform: "translateY(0px) scale(1)" },
    }),
    slideFromTop: keyframes({
      from: { transform: "translateY(-300px) scale(0)" },
      to: { transform: "translateY(0px) scale(1)" },
    }),
    scollPromptSlideUp: keyframes({
      from: { transform: "translateY(300px)" },
      to: { transform: "translateY(0px)" },
    }),
    scollPromptSlideDown: keyframes({
      from: { transform: "translateY(-300px)" },
      to: { transform: "translateY(0px)" },
    }),
  };

  return (
    <Box
      className={className}
      sx={{
        animationName: animations[animation]
          ? animations[animation].toString()
          : null,
        animationDuration: duration ? duration : "800ms",
        animationDelay: delay,
        animationIterationCount: count ? count : "1",
        animationDirection: direction ? direction : "foreward", // foreward | reverse | alternate | alternate-reverse
        animationTimingFunction: timing ? timing : "linear", // ease | linear | ease-in | ease-out | ease-in-out
        // https://stackoverflow.com/questions/42446163/elements-with-animation-delay-briefly-appear-before-fading-in-using-purely-css3
        animationFillMode: "both",
      }}
    >
      {children}
    </Box>
  );
}

export default AnimatedContent;
