//TODO: accessing theme colors not working

// Try:
// May need to use rawColors...
// 1: (theme) => `0 1px 2px ${hexToRgb(theme.colors.primary, 0.3)}, 0 1px 3px ${hexToRgb(theme.colors.primary, 0.3)}`,

const shadows = {
  text: {
    0: "none",
    1: "0 1px 2px rgba(60,64,67,0.302), 0 1px 3px rgba(60,64,67,0.149)",
  },
  box: {
    0: "none",
    1: "0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149)",
  },
};

export default shadows;
