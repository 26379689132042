import { graphql, useStaticQuery } from "gatsby"

const useScaleLinks = () => {
  const data = useStaticQuery(
    graphql`
      {
        allIndexYaml(filter: { label: { eq: "Scales" } }) {
          edges {
            node {
              id
              label
              icon
              description
              items {
                id
                label
                url
                slug
                color
                icon
                component
                publish
                description
              }
            }
          }
        }
      }
    `
  )

  return data.allIndexYaml.edges[0].node
}

export default useScaleLinks
