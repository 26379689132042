/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui";
import {
  FlexMenuLink,
  menuFlexFunctions,
  ScreenReaderText,
  SwapOnMobile,
} from "@smerth/gatsby-theme-theme-ui";

function MenuIcons({
  variant,
  className,
  size,
  data,
  expand,
  iconMap,
  justify,
  gap,
  stackOnMobile,
  showLabelsOnMobile,
}) {
  const {
    calculateFlexProperty,
    calculateListItemPaddingLeft,
    calculateListItemPaddingRight,
    // calculateLinkPadding,
  } = menuFlexFunctions;

  function Icon({ icon }) {
    const IconComponent = iconMap[icon];
    return <IconComponent icon={icon} />;
  }

  return (
    <ul
      className={className}
      sx={{
        padding: 0,
        margin: 0,
        // color: "white",
        // backgroundColor: "inherit",
        listStyleType: "none",
        display: !stackOnMobile ? "flex" : ["block", "block", "flex"],
        flex: "1 0 auto",
        flexWrap: "no-wrap",
        alignContent: "center",
        justifyContent: justify,
        gap: gap ? ["", "", 6] : "",
      }}
    >
      {data.map((item) => {
        return (
          <li
            key={item.id}
            sx={{
              display: "flex",
              flex:
                (expand && justify === "space-between") ||
                (expand && justify === "space-around")
                  ? "1 0 auto"
                  : calculateFlexProperty(justify),
              flexWrap: "no-wrap",
              gap: justify === "center" || justify === "start" ? 3 : 0,
              alignContent: "center",
              listStyleType: "none",
              paddingLeft: calculateListItemPaddingLeft(justify, expand),
              paddingRight: calculateListItemPaddingRight(justify, expand),
            }}
          >
            <FlexMenuLink
              variant={variant}
              item={item}
              noExternalLinkIcon
              sx={{ gap: showLabelsOnMobile ? 6 : 0 }}
            >
              <Box
                as="span"
                variant="layout.svg"
                sx={{
                  fontSize: size,
                }}
              >
                <Icon icon={item.component} />
              </Box>
              <SwapOnMobile
                mobileComponent={
                  showLabelsOnMobile ? (
                    <Text
                      as="p"
                      sx={{ fontFamily: "inherit", fontSize: "inherit" }}
                    >
                      {item.label}
                    </Text>
                  ) : (
                    ""
                  )
                }
                desktopComponent={
                  <ScreenReaderText>{item.label}</ScreenReaderText>
                }
              />
            </FlexMenuLink>
          </li>
        );
      })}
    </ul>
  );
}

export default MenuIcons;
