const images = {
  // default for the Avatar component
  avatar: {
    width: "48px",
    height: "48px",
    borderRadius: "circle",
    bg: "muted",
  },
};

export default images;
